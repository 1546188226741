<template>
  <div>
    <locations />

    <div class="page">
      <account-tab />
      <div class="tab-content wrap-1200" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        >
          <b-form @submit="onSubmit">
            <div class="bp-w440-lt mt-5">
              <div class="row">
                <div class="col-lg-3 bg-gray d-table">
                  <label class="d-table-cell">
                    <span class="required">아이디</span>
                  </label>
                </div>
                <div class="col-lg-9">
                  {{ form.id }}
                </div>
              </div>

              <div class="row">
                <div class="col-lg-3 bg-gray d-table">
                  <label class="d-table-cell"> 벅스 </label>
                </div>
                <div class="col-lg-9 d-flex align-items-center">
                  <span class="me-2 point"> {{ form.point | comma }}B </span>
                  <button
                    class="btn btn-primary btn-xs"
                    type="button"
                    @click="getPointList"
                  >
                    벅스 내역
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-3 bg-gray d-table">
                  <label class="d-table-cell">
                    <span class="required">현재 비밀번호</span>
                  </label>
                </div>
                <div class="col-lg-9 d-flex flex-column flex-sm-row">
                  <input
                    v-if="check_form.passwordchk"
                    type="password"
                    placeholder="현재 비밀번호"
                    name="curpassword"
                    v-model="form.curpassword"
                    required
                  />
                  <input
                    type="password"
                    placeholder="********"
                    disabled
                    v-else
                  />

                  <div
                    class="form-check d-flex align-items-center ms-0 ms-sm-2 mt-2 mt-sm-0"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="passwordchk"
                      id="flexCheck1"
                      v-model="check_form.passwordchk"
                    />
                    <label
                      class="form-check-label text-nowrap"
                      for="flexCheck1"
                    >
                      비밀번호 변경
                    </label>
                  </div>
                </div>
              </div>

              <div class="row" v-if="check_form.passwordchk === true">
                <div class="col-lg-3 bg-gray d-table">
                  <label class="d-table-cell">
                    <span class="required">새 비밀번호</span>
                  </label>
                </div>
                <div class="col-lg-9">
                  <input
                    type="password"
                    placeholder="새 비밀번호"
                    name="newpassword"
                    v-model="form.newpassword"
                  />
                  <small>{{ formValidation.password }} </small>
                </div>
              </div>

              <div class="row" v-if="check_form.passwordchk === true">
                <div class="col-lg-3 bg-gray d-table">
                  <label class="d-table-cell">
                    <span class="required">새 비밀번호 확인</span>
                  </label>
                </div>
                <div class="col-lg-9">
                  <input
                    type="password"
                    placeholder="새 비밀번호 확인"
                    name="newpassword_re"
                    v-model="form.newpassword_re"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-lg-3 bg-gray d-table">
                  <label class="d-table-cell"
                    ><span class="required">닉네임</span>
                  </label>
                </div>
                <div class="col-lg-9">
                  <input
                    type="text"
                    class="btn-addon"
                    placeholder="공백없이 한글, 영문, 숫자만 입력 가능 2글자 이상 20글자 미만"
                    name="nickname"
                    v-model="form.nickname"
                    required
                    @keyup="availability_reset(2)"
                  />
                  <button
                    class="btn btn-primary btn-md check"
                    type="button"
                    @click="availability_check2(2, form.nickname)"
                  >
                    중복확인
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-3 bg-gray d-table">
                  <label class="d-table-cell">
                    <span>프로필 이미지</span>
                  </label>
                </div>
                <div class="col-lg-9">
                  <div class="d-flex flex-wrap">
                    <div class="me-2">
                      <img
                        :src="form._filename | get_img"
                        class="center-cropped border"
                      />
                      <span class="exp">현재 이미지</span>
                    </div>
                    <div class="flex-grow-1 align-self-center">
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control btn-addon"
                          readonly
                          :value="targetFileName"
                        />

                        <button
                          type="button"
                          class="btn btn-primary photo"
                          style="z-index: 1"
                          @click="$refs.file.click()"
                        >
                          이미지변경
                          <input
                            type="file"
                            ref="file"
                            @change="handleFileChange"
                          />
                        </button>
                      </div>
                      <!--
                      <input
                        type="file"
                        class="form-control"
                        ref="file"
                        @change="fileSelect()"
                      />
                      -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 bg-gray d-table">
                  <label class="d-table-cell">
                    <span class="required">성</span>
                  </label>
                </div>
                <div class="col-lg-9">
                  <input
                    type="text"
                    placeholder="공백없이 한글, 영문, 숫자만 입력 가능 20글자 미만"
                    name="firstname"
                    id="firstname"
                    v-model="form.firstname"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 bg-gray d-table">
                  <label class="d-table-cell">
                    <span class="required">이름</span>
                  </label>
                </div>
                <div class="col-lg-9">
                  <input
                    type="text"
                    placeholder="공백없이 한글, 영문, 숫자만 입력 가능 20글자 미만"
                    name="lastname"
                    id="lastname"
                    v-model="form.lastname"
                    required
                  />
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-lg-3 bg-gray d-table">
                  <label class="d-table-cell">
                    <span class="required">성</span>
                  </label>
                </div>
                <div class="col-lg-9 name">
                  <input
                    type="text"
                    placeholder=""
                    name="lastname"
                    id="lastname"
                    v-model="form.lastname"
                    required
                  />
                </div>
              </div> -->
              <div class="row">
                <div class="col-lg-3 bg-gray d-table">
                  <label class="d-table-cell">
                    <span class="required">이메일</span>
                  </label>
                </div>
                <div class="col-lg-9 d-flex flex-column flex-sm-row">
                  <input
                    type="text"
                    name="email"
                    v-model="form.email"
                    readonly
                  />

                  <div
                    class="form-check d-flex align-items-center ms-0 ms-sm-2 mt-2 mt-sm-0"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheck2"
                      name="emailchk"
                      v-model="check_form.emailchk"
                      @click="availability_reset(3)"
                    />
                    <label class="form-check-label text-nowrap" for="flexCheck2"
                      >이메일 변경</label
                    >
                  </div>
                </div>
              </div>

              <div class="row" v-if="check_form.emailchk === true">
                <div class="col-lg-3 bg-gray d-table">
                  <label class="d-table-cell">
                    <span class="required">새 이메일</span>
                  </label>
                </div>
                <div class="col-lg-9">
                  <input
                    type="text"
                    class="btn-addon"
                    placeholder="공백없이 100글자 미만"
                    name="newemail"
                    id="newemail"
                    v-model="form.newemail"
                  />
                  <button
                    class="btn btn-primary btn-md check"
                    type="button"
                    @click="availability_check2(3, form.newemail)"
                  >
                    중복확인
                  </button>
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-lg-3 bg-gray d-table">
                  <label class="d-table-cell">
                    <span class="required">표준시간대</span>
                  </label>
                </div>
                <div class="col-lg-9">
                  <div class="row">
                    <div class="col-8">
                      <select
                        class="d-inline-block"
                        name="timezone"
                        v-model="form.timezone"
                        required
                      >
                        <option
                          v-for="(tz, index) in timezone_arr"
                          :value="tz"
                          :key="index + 't'"
                        >
                          UTC{{ tz >= 0 ? "+" + tz : tz }} : 00
                        </option>
                      </select>
                    </div>
                    <div class="col-4">
                      <div class="form-check d-inline-block">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexCheck3"
                          name="daylighttime"
                          v-model="check_form.daylighttime"
                          :checked="check_form.daylighttime"
                        />
                        <label
                          class="form-check-label daylight"
                          for="flexCheck3"
                          >일광절약시간
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="row">
                <div class="col-lg-3 bg-gray d-table">
                  <label class="d-table-cell"
                    ><span class="required">성별</span></label
                  >
                </div>
                <div class="col-lg-9 d-table">
                  <div class="d-table-cell align-middle">
                    <div class="form-check d-inline-block me-3">
                      <input
                        class="form-check-input"
                        type="radio"
                        value="M"
                        id="Male"
                        name="gender"
                        v-model="form.gender"
                        :checked="form.gender === 'M'"
                      />
                      <label class="form-check-label" for="Male">남성</label>
                    </div>
                    <div class="form-check d-inline-block">
                      <input
                        class="form-check-input"
                        type="radio"
                        value="F"
                        id="Female"
                        name="gender"
                        v-model="form.gender"
                        :checked="form.gender === 'F'"
                      />
                      <label class="form-check-label" for="Female">여성</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-3 bg-gray d-table">
                  <label class="d-table-cell">
                    <span class="required">오른/왼손잡이</span>
                  </label>
                </div>
                <div class="col-lg-9 d-table">
                  <div class="d-table-cell align-middle">
                    <div class="form-check d-inline-block me-3">
                      <input
                        class="form-check-input"
                        type="radio"
                        value="R"
                        id="Right"
                        name="hand"
                        v-model="form.hand"
                        :checked="form.hand === 'R'"
                      />
                      <label class="form-check-label" for="Right">오른손</label>
                    </div>
                    <div class="form-check d-inline-block">
                      <input
                        class="form-check-input"
                        type="radio"
                        value="L"
                        id="Left"
                        name="hand"
                        v-model="form.hand"
                        :checked="form.hand === 'L'"
                      />
                      <label class="form-check-label" for="Left"> 왼손 </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-3 bg-gray d-table">
                  <label class="d-table-cell">
                    <span class="required">티 높이</span>
                  </label>
                </div>
                <div class="col-lg-9">
                  <select
                    name="teeheight"
                    id="teeheight"
                    v-model="form.teeheight"
                  >
                    <option
                      v-for="(height, index) in teeheight_arr"
                      :selected="form.teeheight === height"
                      :key="index"
                      :value="height"
                    >
                      {{ height }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-3 bg-gray d-table">
                  <label class="d-table-cell">
                    <span class="required">장비설정</span>
                  </label>
                </div>
                <div class="col-lg-9">
                  <div class="row">
                    <div class="col-lg-4 set">
                      <select
                        name="fieldunit"
                        id="fieldunit"
                        v-model="form.fieldunit"
                        requried
                      >
                        <option value="" disabled>페어웨이</option>
                        <option value="1" :selected="form.fieldunit === '1'">
                          M
                        </option>
                        <option value="2" :selected="form.fieldunit === '2'">
                          Yard
                        </option>
                        <option value="3" :selected="form.fieldunit === '3'">
                          Feet
                        </option>
                      </select>
                    </div>
                    <div class="col-lg-4 set">
                      <select
                        name="greenunit"
                        id="greenunit"
                        v-model="form.greenunit"
                        required
                      >
                        <option value="" disabled>그린</option>
                        <option value="1" :selected="form.greenunit === '1'">
                          M
                        </option>
                        <option value="2" :selected="form.greenunit === '2'">
                          Yard
                        </option>
                        <option value="3" :selected="form.greenunit === '3'">
                          Feet
                        </option>
                      </select>
                    </div>
                    <div class="col-lg-4 set">
                      <select
                        name="speedunit"
                        id="speedunit"
                        v-model="form.speedunit"
                        required
                      >
                        <option value="" disabled>속도</option>
                        <option value="1" :selected="form.speedunit === '1'">
                          km/h
                        </option>
                        <option value="2" :selected="form.speedunit === '2'">
                          mile/h
                        </option>
                        <option value="3" :selected="form.speedunit === '3'">
                          m/s
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="agree_box px-2 px-lg-4">
                <div class="form-check">
                  <input
                    type="checkbox"
                    id="emailagree"
                    class="form-check-input custom-checkbox"
                    v-model="check_form.emailagree"
                    :checked="check_form.emailagree"
                  />
                  <label class="form-check-label" for="emailagree">
                    X-GOLF로부터 프로모션 및 혜택에 대한 이메일을 받는 것에
                    동의합니다. (본 동의는 언제든지 취소할 수 있습니다.)
                  </label>
                </div>

                <div class="agree_box">
                  <vue-recaptcha
                    :sitekey="RECAPTCHA_SITEKEY"
                    :loadRecaptchaScript="true"
                    @verify="verify"
                  ></vue-recaptcha>
                </div>
              </div>

              <div class="btn-bottom-wrapper">
                <b-button
                  type="submit"
                  size="xl"
                  variant="primary"
                  aria-label="적용"
                  >적용</b-button
                >
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>

    <b-modal
      id="pointListModal"
      ref="pointListModal"
      size="lg"
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header>
        <h3>벅스 내역</h3>
        <button
          type="button"
          class="btn-close"
          @click="$refs['pointListModal'].hide()"
        >
          <i class="material-icons">close</i>
        </button>
      </template>
      <p class="modal-subtitle">
        벅스 합계 <span class="point">{{ pointSum | comma }}B</span>
      </p>
      <table class="board table" id="dataTable" width="100%" cellspacing="0">
        <thead>
          <tr>
            <th class="col-auto d-none d-lg-table-cell">No.</th>
            <th class="col-auto d-none d-lg-table-cell">닉네임</th>
            <th class="col-auto d-lg-table-cell">벅스</th>
            <th class="col-auto d-lg-table-cell">비고</th>
            <th class="col-auto d-none d-lg-table-cell">벅스 생성일</th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="pointListCnt">
          <template v-for="(item, index) in pointList">
            <tr :key="index">
              <td class="col-auto d-none d-lg-table-cell">
                {{ pointList.length - index }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.nickname }}
              </td>
              <td class="col-auto d-lg-table-cell point">
                {{ item.points | comma }}
              </td>
              <td class="col-auto d-lg-table-cell">
                {{ item.description || item.title }}
              </td>
              <td class="col-auto d-none d-lg-table-cell text-date">
                {{ item.regdate | GMTtuUTC }}
              </td>

              <td class="td-arrow" @click="toggle_tr(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="col-auto d-lg-none d-md-tabel-cell"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td class="align-left bg-white" colspan="5">
                <div class="toggle-content">
                  <div class="row">
                    <div class="col-4">No.</div>
                    <div class="col-8">{{ item.no }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">닉네임</div>
                    <div class="col-8">{{ item.nickname }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">벅스 생성일</div>
                    <div class="col-8">
                      {{ item.regdate | GMTtuUTC }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="text-center">데이터가 없습니다.</td>
          </tr>
        </tbody>
      </table>
      <template #modal-footer="{}">
        <b-button
          size="md"
          variant="outline-secondary"
          aria-label="닫기"
          @click="$bvModal.hide('pointListModal')"
        >
          닫기
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import locations from "@/components/Location";
import AccountTab from "@/components/membership/AccountTab";
import ax from "@/api/membership";
import VueRecaptcha from "vue-recaptcha";
import "@/filter/common";

export default {
  name: "MyProfile",
  data() {
    return {
      profile: [],
      form: {
        id: "",
        recaptcha_yn: "n",
        recaptcha_token: "",
        passwordchk: "",
        curpassword: "",
        newpassword: "",
        newpassword_re: "",
        email: "",
        emailchk: "0",
        emailoverlap: "0",
        newemail: "",
        nickname: "",
        nicknameoverlap: "0",
        firstname: "",
        lastname: "",
        timezone: "9",
        daylighttime: "0",
        gender: "M",
        hand: "R",
        teeheight: "0",
        fieldunit: "",
        greenunit: "",
        speedunit: "",
        emailagree: "0",
        filename: null,
        _filename: null,
      },
      check_form: {
        nick_chk: "n",
        email_chk: "n",
        daylighttime: false,
        emailagree: false,
        passwordchk: false,
        emailchk: false,
      },
      formValidation: {
        password:
          "비밀번호는 영문, 숫자를 포함하여 최소 8자, 최대 30자 이내로 입력해주세요.",
      },
      teeheight_arr: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
      timezone_arr: [
        -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8,
        9, 10, 11,
      ],

      pointList: [],
      pointListCnt: 0,
      pointSum: 0,
      view_hide: null,
      RECAPTCHA_SITEKEY: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      VUE_APP_S3_BUCKET_URL: process.env.VUE_APP_S3_BUCKET_URL,
      targetFileName: null,
    };
  },
  components: {
    AccountTab,
    locations,
    VueRecaptcha,
  },
  methods: {
    get_memberinfo() {
      ax.get_memberinfo((flag, data, code) => {
        if (flag) {
          // console.log(data.memberinfo[0]);
          this.form = data.memberinfo;
          this.check_form.daylighttime = this.form.daylighttime === "1";
          this.check_form.emailagree = this.form.emailagree === "1";
          this.form.nicknameoverlap = "1";
          this.form.emailoverlap = "1";
          this.check_form.nick_chk = "y";
          this.check_form.email_chk = "n";
          this.form.newpassword = "";
          this.form.newemail = "";
          this.form._filename = this.form.member_photo_url;
        } else {
          if (code == "300") {
            this.$notify({
              group: "alert",
              type: "warn",
              text: data,
              duration: 5000,
              speed: 1000,
            });
          } else alert(data);
          if (code === "110") {
            this.$store.dispatch("logout");
            this.$router.push("/");
          }
          // alert(data);
        }
      });
    },
    getPointList() {
      ax.getPointList((flag, data, code) => {
        if (flag) {
          this.pointList = data.pointlist;
          this.pointListCnt = data.pointlistcnt;
          this.pointSum = data.pointsum;
          this.$refs["pointListModal"].show({ "z-index": 9999 });
        } else {
          this.pointList = [];
          this.pointListCnt = 0;
          this.pointSum = 0;
          this.$refs["pointListModal"].show({ "z-index": 9999 });
          if (code == 300) {
            this.$notify({
              group: "alert",
              type: "warn",
              text: data,
              duration: 5000,
              speed: 1000,
            });
          } else alert(data);
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.form.daylighttime =
        this.check_form.daylighttime === true ? "1" : "0";
      this.form.emailagree = this.check_form.emailagree === true ? "1" : "0";
      this.form.passwordchk = this.check_form.passwordchk === true ? "1" : "0";
      this.form.emailchk = this.check_form.emailchk === true ? "1" : "0";

      if (this.form.passwordchk == "1") {
        if (this.form.curpassword === "") {
          alert("현재 비밀번호를 입력하세요.");
          return false;
        }

        if (this.form.newpassword === "") {
          alert("새 비밀번호를 입력하세요.");
          return false;
        }

        if (this.form.newpassword_re === "") {
          alert("새로운 패스워드 확인를 입력하세요.");
          return false;
        }

        if (this.form.newpassword !== this.form.newpassword_re) {
          alert("새 비밀번호와 새 비밀번호 확인이 일치하지 않습니다.");
          return false;
        }
      }

      if (this.form.nickname === "") {
        alert("닉네임을 입력해주세요.");
        return false;
      }
      if (this.check_form.nick_chk === "n") {
        alert("닉네임을 다시 한번 확인해주세요.");
        this.form.nicknameoverlap = "0";
        return false;
      }
      this.form.nicknameoverlap = "1";

      if (this.form.lastname === "") {
        alert("이름을 입력하세요.");
        return false;
      }

      if (this.form.firstname === "") {
        alert("성을 입력하세요.");
        return false;
      }

      if (this.form.email === "") {
        alert("이메일을 입력하세요.");
        return false;
      }
      if (this.form.emailchk == "1") {
        if (this.form.newemail === "") {
          alert("새 이메일을 입력하세요.");
          return false;
        }
        if (this.check_form.email_chk === "n") {
          alert("새 이메일을 다시 확인하세요.");
          this.form.emailoverlap = "0";
          return false;
        }
        this.form.emailoverlap = "1";
      }

      if (this.form.recaptcha_yn === "n") {
        alert("자동등록방지를 확인하세요.");
      }

      ax.member_edit(this.form, (flag, code, msg) => {
        if (flag) {
          if (code == "200") {
            alert(msg);
            location.reload();
          }
        } else {
          if (code == "300") {
            alert(msg);
            // location.reload();
            return false;
          } else {
            alert(msg);
            return false;
          }
        }
      });
    },
    availability_reset(type) {
      if (type === 2) {
        this.check_form.nick_chk = "n";
      } else if (type === 3) {
        this.check_form.email_chk = "n";
      }
    },
    verify(response) {
      this.form.recaptcha_token = response;
      this.form.recaptcha_yn = "y";
    },
    availability_check2(type, name) {
      if (name === "") {
        // alert('데이터를 넣어주세요')
        if (type === 2) {
          alert("닉네임을 입력해주세요.");
        } else if (type === 3) {
          alert("이메일을 입력하세요.");
        }
        return false;
      }

      ax.availability_check2(type, name, (flag, msg, code) => {
        if (flag) {
          if (code === 200) {
            if (type === 2) {
              this.check_form.nick_chk = "y";
              alert(msg);
            } else if (type === 3) {
              this.check_form.email_chk = "y";
              alert(msg);
            }
          }
        } else {
          // alert(code)
          if (code == "300") {
            if (type === 2) {
              alert(msg);
            } else if (type === 3) {
              alert(msg);
            }
            // alert('Duplicate data.')
          } else {
            alert(msg);
          }
          return false;
        }
      });
    },
    fileSelect() {
      this.form.filename = this.$refs.file.files[0];
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    handleFileChange(e) {
      this.targetFileName = e.target.files[0].name;
      this.form.filename = this.$refs.file.files[0];
    },
  },
  // watch: {
  //   "form.newpassword": {
  //     handler: function (val) {
  //       const idReg2 = /(?=.*\d)(?=.*[a-zA-ZS]).{8,30}/; // 문자, 숫자 1개이상 포함, 8자리 이상
  //       if (!idReg2.test(val)) {
  //         this.formValidation.password =
  //           "Please enter a password of at least 8 characters and a maximum of 30 characters with including letters and numbers.";
  //       } else {
  //         this.formValidation.password = "this password is available.";
  //       }
  //     },
  //   },
  // },
  created() {
    this.get_memberinfo();
  },
};
</script>
