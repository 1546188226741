<template>
  <div>
    <location />

    <div class="p23 page">
      <tab />

      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-3"
          role="tabpanel"
          aria-labelledby="pills-3-tab"
        >
          <div class="wrap-1200">
            <b-form @submit="onSubmit">
              <!-- h3 class="montserrat">Search swing videos</h3 -->
              <div class="date-search">
                <div class="input-date-box">
                  <input
                    type="date"
                    name="startdate"
                    v-model="searchData.startdate"
                  />
                  <input
                    type="date"
                    name="enddate"
                    v-model="searchData.enddate"
                  />
                  <input
                    class="btn btn-primary search"
                    type="submit"
                    value=""
                  />
                </div>
              </div>
            </b-form>
          </div>

          <table
            class="board table wrap-1200"
            id="dataTable"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr>
                <th class="col-auto d-none d-lg-table-cell text-nowrap">
                  번호
                </th>
                <th class="col-auto d-lg-table-cell text-center">스윙 영상</th>
                <th class="col-auto d-none d-lg-table-cell text-center">
                  제목
                </th>
                <th class="col-auto d-none d-lg-table-cell text-center">
                  날짜
                </th>
                <th class="col-auto d-lg-table-cell text-center">매장</th>
                <th class="col-auto d-none d-lg-table-cell">상태</th>
                <th class="col-auto d-none d-lg-table-cell">관리</th>
                <th class="th-arrow">&nbsp;</th>
              </tr>
            </thead>
            <tbody v-if="data_yn">
              <template v-for="(item, index) in items">
                <tr :key="index">
                  <td class="col-auto d-none d-lg-table-cell">{{ item.no }}</td>
                  <td class="col-auto d-lg-table-cell">
                    <router-link
                      :to="{
                        name: 'MySwingView',
                        params: { id: item.swingno },
                        query: {
                          startdate: searchData.startdate,
                          enddate: searchData.enddate,
                        },
                      }"
                      class="position-relative video-box"
                    >
                      <video :src="item.path + '/' + item.swingmovie"></video>
                      <div class="mask"></div>
                      <span class="icon-play"></span>
                    </router-link>
                  </td>
                  <td class="col-auto d-none d-lg-table-cell text-start">
                    <router-link
                      :to="{
                        name: 'MySwingView',
                        params: { id: item.swingno },
                        query: {
                          startdate: searchData.startdate,
                          enddate: searchData.enddate,
                        },
                      }"
                      class="position-relative video-box"
                    >
                      <span class="more me-3">{{ item.title }}</span>
                      <span
                        class="text-lightgray"
                        v-if="item.title && item.replycnt > 0"
                        ><i class="material-icons-outlined">textsms</i>
                        {{ item.replycnt | comma }}</span
                      >
                    </router-link>
                  </td>
                  <td class="col-auto d-none d-lg-table-cell text-center text-date">
                    {{ item.date | GMTtuUTCTIME }}
                  </td>
                  <td class="col-auto d-lg-table-cell shop-name">
                    {{ item.shopname }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    {{ item.flag == "0" ? "비공개" : "공개" }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-xs"
                      @click="
                        $refs['settingModal'].modalOuterStyle.zIndex = 9999;
                        $refs['settingModal'].show();
                        sendData.swingno = item.swingno;
                        sendData.title = item.title;
                        sendData.content = item.content;
                        sendData.flag = item.flag;
                      "
                    >
                      수정
                    </button>

                    <button
                      type="button"
                      class="btn btn-primary btn-xs"
                      @click="delete_swing(item.swingno)"
                    >
                      삭제
                    </button>
                  </td>
                  <td class="td-arrow" @click="toggle_tr(index)">
                    <img
                      src="/img/arrow_none_collapsed.png"
                      :class="{ 'btn-toggle-arrow': view_hide === index }"
                    />
                  </td>
                </tr>
                <tr
                  class="col-auto d-lg-none d-md-tabel-cell"
                  :class="{ show: view_hide === index }"
                  :key="index + 'r'"
                  v-if="view_hide === index"
                >
                  <td class="align-left bg-white" colspan="7">
                    <div class="toggle-content">
                      <div class="row">
                        <div class="col-2">번호</div>
                        <div class="col-10">{{ item.no }}</div>
                      </div>

                      <div class="row">
                        <div class="col-2">제목</div>
                        <div class="col-10">{{ item.title }}</div>
                      </div>

                      <div class="row">
                        <div class="col-2">날짜</div>
                        <div class="col-10 text-date">
                          {{ item.date | GMTtuUTCTIME }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">상태</div>
                        <div class="col-10">
                          {{ item.flag == "0" ? "Private" : "Public" }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2">관리</div>
                        <div class="col-10 d-flex">
                          <button
                            type="button"
                            class="btn btn-outline-primary btn-xs"
                            @click="
                              $refs[
                                'settingModal'
                              ].modalOuterStyle.zIndex = 9999;
                              $refs['settingModal'].show();
                              sendData.swingno = item.swingno;
                              sendData.title = item.title;
                              sendData.content = item.content;
                              sendData.flag = item.flag;
                            "
                          >
                            수정
                          </button>

                          <button
                            type="button"
                            class="btn btn-primary btn-xs"
                            @click="delete_swing(item.swingno)"
                          >
                            삭제
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7" class="text-center">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="page_total"
            v-model="searchData.pageno"
            use-router
            aria-label="Page navigation example"
            class="mt-3"
            v-if="page_total > 1"
            first-class="prev"
            prev-class="prev"
            next-class="next"
            last-class="next"
            hide-ellipsis
            limit="10"
          >
            <template #first-text>
              <i class="material-icons">keyboard_double_arrow_left</i>
            </template>
            <template #prev-text>
              <i class="material-icons">navigate_before</i>
            </template>
            <template #next-text>
              <i class="material-icons">navigate_next</i>
            </template>
            <template #last-text>
              <i class="material-icons">keyboard_double_arrow_right</i>
            </template>
            <template #page="{ page, active }">
              <a v-if="active">{{ page }}</a>
              <a v-else>{{ page }}</a>
            </template>
          </b-pagination-nav>
        </div>
      </div>
    </div>
    <b-modal id="settingModal" ref="settingModal" size="lg" footer-class="btn-bottom-wrapper">
      <template #modal-header>
        <h3>스윙영상관리</h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="$refs['settingModal'].hide()"
        >
          <i class="material-icons">close</i>
        </button>
      </template>
      <div class="row g-0 border-top-dgray border-bottom">
        <div class="col-lg-3 d-table bg-gray">
          <label class="d-table-cell required text-center">제목</label>
        </div>
        <div class="col-lg d-table">
          <input type="text" class="form-control-lg" v-model="sendData.title" />
        </div>
      </div>

      <div class="row g-0 border-bottom">
        <div class="col-lg-3 d-table bg-gray">
          <label class="d-table-cell text-center">내용</label>
        </div>
        <div class="col-lg d-table">
          <!-- <input
            type="text"
            class="form-control-lg"
            v-model="sendData.content"
          /> -->
          <textarea name="contents" id="" v-model="sendData.content"></textarea>
        </div>
      </div>
      <div class="row g-0 border-bottom">
        <div class="col-lg-3 d-table bg-gray">
          <label class="d-table-cell text-center">상태</label>
        </div>
        <div class="col-lg d-table">
          <div class="form-check d-inline-block me-3">
            <input
              class="form-check-input"
              type="radio"
              id="Private"
              value="0"
              v-model="sendData.flag"
              :checked="sendData.flag == '0'"
            />
            <label class="form-check-label" for="Private">비공개</label>
          </div>
          <div class="form-check d-inline-block">
            <input
              class="form-check-input"
              type="radio"
              id="Public"
              value="1"
              v-model="sendData.flag"
              :checked="sendData.flag == '1'"
            />
            <label class="form-check-label" for="Public">공개</label>
          </div>
        </div>
      </div>
<!--  <template #modal-footer="{}">
        <b-button
          size="md" variant="secondary" aria-label="수정"
          @click="confirm"
        >
          수정
        </b-button>
        <b-button
          size="md"
          variant="outline-secondary"
          aria-label="취소"
          @click="$bvModal.hide('settingModal')"
        >
          취소
        </b-button>
      </template> -->
      <template #modal-footer="{}">
        <b-button size="md" variant="outline-secondary" aria-label="취소" @click="$bvModal.hide('settingModal')"
          >취소</b-button
        >
        <b-button size="md" variant="secondary" aria-label="수정" @click="confirm"
          >수정</b-button
        >
      </template>

    </b-modal>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import ax from "@/api/membership";

export default {
  name: "MySwing",
  components: {
    location,
    tab,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        startdate:
          this.$route.query.startdate ||
          this.$moment(new Date().setMonth(new Date().getMonth() - 1)).format(
            "YYYY-MM-DD"
          ),
        enddate:
          this.$route.query.enddate ||
          this.$moment(new Date()).format("YYYY-MM-DD"),
      },
      sendData: {
        swingno: 0,
        content: "",
        title: "",
        flag: 0,
      },
      page_total: 1,

      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      view_hide: null,
    };
  },
  methods: {
    get_memberswinglist() {
      ax.get_memberswinglist(this.searchData, (flag, data, code = "") => {
        if (flag) {
          this.total_cnt = data.swinglistcnt;
          this.page_total = Math.ceil(data.swinglistcnt / this.rows);
          this.items = data.swinglist;
        } else {
          this.page_total = 0;

          // alert(data);
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            startdate: this.searchData.startdate,
            enddate: this.searchData.enddate,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return pageNum === 1
        ? `?startdate=${this.searchData.startdate}&enddate=${this.searchData.enddate}`
        : `?page=${pageNum}&startdate=${this.searchData.startdate}&enddate=${this.searchData.enddate}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    change_flag(idx, change_flag) {
      console.log(idx, change_flag);
      ax.set_memberswingopen(idx, change_flag, (flag, data) => {
        alert(data);
        this.get_memberswinglist();
      });
    },
    confirm() {
      if (this.sendData.title === "") {
        alert("Title is required.");
        return false;
      }

      ax.set_swingtitlewrite(
        this.sendData.swingno,
        this.sendData.title,
        this.sendData.content,
        this.sendData.flag,
        (flag, data) => {
          if (flag) {
            this.change_flag(this.sendData.swingno, this.sendData.flag);
            this.$refs["settingModal"].hide();
            this.sendData.swingno = 0;
            this.sendData.title = "";
            this.sendData.content = "";
            this.sendData.flag = 0;
          } else {
            alert(data);
          }
        }
      );
    },
    delete_swing(idx) {
      ax.memberswingdrop(idx, (flag, data) => {
        alert(data);
        this.get_memberswinglist();
      });
    },
  },
  created() {
    this.get_memberswinglist();
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.get_memberswinglist();
    },
  },
};
</script>
