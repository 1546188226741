<template>
  <div>
    <swiper
      :options="{
        slidesPerView: 'auto',
        freeMode: true,
        initialSlide: tab - 1,
      }"
      class="swiper mySwiper"
    >
      <swiper-slide :class="{ active: tab === 1 }"
        ><div
          @click="
            $router.push('/membership/myaccount/myprofile').catch(() => {})
          "
        >
          개인정보수정
        </div></swiper-slide
      >
      <swiper-slide :class="{ active: tab === 2 }"
        ><div
          @click="
            $router
              .push('/membership/myaccount/favoritelocation')
              .catch(() => {})
          "
        >
          단골매장
        </div></swiper-slide
      >
      <swiper-slide :class="{ active: tab === 3 }"
        ><div
          @click="
            $router
              .push('/membership/myaccount/confirmpassword')
              .catch(() => {})
          "
        >
          회원탈퇴
        </div></swiper-slide
      >
    </swiper>
    <ul class="nav nav-pills wrap-1200" id="pills-tab" role="tablist">
      <li class="nav-item col-4" role="presentation">
        <button
          class="w-100 nav-link"
          id="pills-1-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-1"
          type="button"
          role="tab"
          aria-controls="pills-1"
          aria-selected="true"
          @click="
            $router.push('/membership/myaccount/myprofile').catch(() => {})
          "
          :class="{ active: $route.name === 'MyProfile' }"
        >
          개인정보수정
        </button>
      </li>
      <li class="nav-item col-4" role="presentation">
        <button
          class="w-100 nav-link"
          id="pills-2-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-2"
          type="button"
          role="tab"
          aria-controls="pills-2"
          aria-selected="false"
          @click="
            $router
              .push('/membership/myaccount/favoritelocation')
              .catch(() => {})
          "
          :class="{ active: $route.name === 'FavoriteLocation' }"
        >
          단골매장
        </button>
      </li>
      <li class="nav-item col-4" role="presentation">
        <button
          class="w-100 nav-link"
          id="pills-3-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-3"
          type="button"
          role="tab"
          aria-controls="pills-3"
          aria-selected="false"
          @click="
            $router
              .push('/membership/myaccount/confirmpassword')
              .catch(() => {})
          "
          :class="{ active: $route.name === 'CancelMember' }"
        >
          회원탈퇴
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "AccountTab",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      /*swiperOptions: {
        slidesPerView: 'auto',
        freeMode: true
      }*/
    };
  },
  computed: {
    tab: function () {
      if (this.$route.name === "MyProfile") return 1;
      else if (this.$route.name === "FavoriteLocation") return 2;
      else if (this.$route.name === "CancelMember") return 3;
      else return 4;
    },
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
};
</script>

<style scoped>
@media (max-width: 992px) {
  .swiper-slide div {
    font-size: 16px !important;
  }
}
</style>
