<template>
  <div>
    <location />

    <div class="p19 page">
      <tab />

      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-2"
          role="tabpanel"
          aria-labelledby="pills-2-tab"
        >
          <div class="wrap-1200">
            <h3 class="subtitle">{{ tournamentname }}</h3>
          </div>
          <table class="board table wrap-1200" width="100%" cellspacing="0">
            <thead>
              <tr>
                <th class="col-auto d-none d-lg-table-cell">날짜</th>
                <th class="col-auto d-lg-table-cell">코스</th>
                <th class="col-auto d-none d-lg-table-cell">타수</th>
                <th class="col-auto d-none d-lg-table-cell">스코어카드</th>
                <th class="th-arrow">&nbsp;</th>
              </tr>
            </thead>
            <tbody v-if="data_yn">
              <template v-for="(item, index) in items">
                <tr class="btn1" @click="toggle_tr(index)" :key="index">
                  <td class="col-auto d-none d-lg-table-cell date">
                    {{
                      $moment(item.startdate).format("YYYY-MM-DD HH:MM:SS")
                    }}
                    <!-- {{
                      $moment(item.startdate).format("YYYY-MM-DD HH:MM:SS") +
                      " ~ " +
                      $moment(item.enddate).format("YYYY-MM-DD")
                    }} -->
                  </td>
                  <td class="col-auto d-lg-table-cell">
                    {{ item.coursename }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    {{ item.score | comma }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-xs"
                      @click="
                        $router.push({
                          name: 'MyScorecard',
                          params: { id: searchData.tournamentno },
                          query: { gameno: item.gameno },
                        })
                      "
                    >
                      스코어카드
                    </button>
                  </td>
                  <td class="td-arrow">
                    <img
                      src="/img/arrow_none_collapsed.png"
                      :class="{ 'btn-toggle-arrow': view_hide === index }"
                    />
                  </td>
                </tr>
                <tr
                  class="col-auto d-lg-none d-md-tabel-cell"
                  :class="{ show: view_hide === index }"
                  :key="index + 'r'"
                  v-if="view_hide === index"
                >
                  <td class="align-left bg-white" colspan="5">
                    <div class="layer1">
                      <div class="row g-0">
                        <div class="col-4">기간</div>
                        <div class="col-8 date">
                          {{
                            $moment(item.startdate).format("YYYY-MM-DD") +
                            "~" +
                            $moment(item.enddate).format("YYYY-MM-DD")
                          }}
                        </div>
                      </div>
                      <div class="row g-0">
                        <div class="col-4">타수</div>
                        <div class="col-8">{{ item.score }}</div>
                      </div>
                      <div class="row g-0">
                        <div class="col-4">스코어카드</div>
                        <div class="col-8">
                          <button
                            type="button"
                            class="btn btn-primary btn-xs pr-4 pl-4"
                            @click="
                              $router.push({
                                name: 'MyScorecard',
                                params: { id: searchData.tournamentno },
                                query: { gameno: item.gameno },
                              })
                            "
                          >
                            스코어카드
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="4" class="text-center">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="page_total"
            v-model="searchData.pageno"
            use-router
            aria-label="Page navigation example"
            class="mt-3"
            v-if="page_total > 1"
            first-class="prev"
            prev-class="prev"
            next-class="next"
            last-class="next"
            hide-ellipsis
            limit="10"
          >
            <template #first-text>
              <i class="material-icons">keyboard_double_arrow_left</i>
            </template>
            <template #prev-text>
              <i class="material-icons">navigate_before</i>
            </template>
            <template #next-text>
              <i class="material-icons">navigate_next</i>
            </template>
            <template #last-text>
              <i class="material-icons">keyboard_double_arrow_right</i>
            </template>
            <template #page="{ page, active }">
              <a v-if="active">{{ page }}</a>
              <a v-else>{{ page }}</a>
            </template>
          </b-pagination-nav>
        </div>

        <!--         <div class="wrap-1200 start-m row center-btn">
          <div class="col-lg-4 d-table m-auto">
            <button
              class="btn btn-primary btn-xl"
              type="button"
              @click="click_list()"
            >
              목록
            </button>
          </div>
        </div> -->

        <div class="btn-bottom-wrapper">
          <b-button size="xl" variant="outline-primary" aria-label="목록" @click="click_list()">목록</b-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import ax from "@/api/membership";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "MyTournamentsAttend",
  components: {
    location,
    tab,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        tournamentno: this.$route.params.id,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      view_hide: null,
      tournamentname: "",
    };
  },
  methods: {
    get_membertournamentplaylist() {
      ax.get_membertournamentplaylist(
        this.searchData,
        (flag, data, code = "") => {
          if (flag) {
            if (data.tournamentplaylistcnt <= 0) {
              this.data_yn = false;
              // return false;
            } else {
              this.data_yn = true;
            }
            this.total_cnt = data.tournamentplaylistcnt;
            this.page_total = Math.ceil(data.tournamentplaylistcnt / this.rows);
            this.items = data.tournamentplaylist;
            this.tournamentname = data.tournamentname;
          } else {
            this.data_yn = false;
            this.page_total = 0;
            if (code == "300") {
              this.$notify({
                group: "alert",
                type: "warn",
                text: data,
                duration: 5000,
                speed: 1000,
              });
            }
            // alert(data);
          }
        }
      );
    },
    click_list() {
      this.$router.push({
        name: "MyTournaments",
        query: {
          startdate: this.$route.query.startdate,
          enddate: this.$route.query.enddate,
        },
      });
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_membertournamentplaylist();
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.get_membertournamentplaylist();
    },
  },
  mixins: [myMixin],
};
</script>