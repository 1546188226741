<template>
  <div id="scorecard">
    <div class="round-summary">
      <h5>
        <MemberInfo :nickname="scoreCardInfo.nickname" style="float: left" />
        님의 스코어카드
      </h5>
      <div class="d-flex">
        <div class="flex-grow-1">
          <p>{{ scoreCardInfo.shopname }}</p>
          <p class="date">{{ scoreCardInfo.date }}</p>
          <span class="badge type-play stroke"
            >{{ play_type(scoreCardInfo.playtype) }}
          </span>
          <!-- <span class="badge type-play newperio">new perio</span> -->
        </div>
        <div class="flex-shrink-0 total-score">
          {{ scoreCardStats.shot }}
          <small>( {{ scoreCardTotal.mulligantotal || 0 }} )</small>
        </div>
      </div>
    </div>

    <div
      class="round-record"
      v-for="(anc, index1) in subCourseList"
      :key="index1"
    >
      <h6>
        {{ scoreCardInfo.coursename }} -
        {{
          subCourseList[index1].subcoursename == "OUT"
            ? "OUT"
            : subCourseList[index1].subcoursename
        }}
      </h6>
      <div class="row g-0 bg-gray">
        <div class="col-2">
          <div>홀</div>
        </div>
        <div class="col" v-for="index2 in 9" :key="index2">
          <div>{{ scoreCardHolePar[9 * index1 + index2 - 1].holeno }}</div>
        </div>
        <div class="col" v-if="index1 == 0">
          <div>아웃</div>
        </div>
        <div class="col" v-else>
          <div>인</div>
        </div>
      </div>

      <div class="row g-0">
        <div class="col-2">
          <div>파</div>
        </div>
        <div class="col" v-for="index3 in 9" :key="index3">
          <div>{{ scoreCardHolePar[9 * index1 + index3 - 1].par }}</div>
        </div>
        <div class="col" v-if="index1 == 0">
          <div>{{ scoreCardTotal.partotal1 }}</div>
        </div>
        <div class="col" v-else>
          <div>{{ scoreCardTotal.partotal2 }}</div>
        </div>
      </div>

      <div class="row g-0">
        <div class="col-2">
          <div>타수</div>
        </div>
        <div class="col" v-for="index4 in 9" :key="index4">
          <div>
            {{
              scoreCardScore[9 * index1 + 0]
                ? scoreCardScore[9 * index1 + index4 - 1].shot || 0
                : 0
            }}
          </div>
        </div>
        <div class="col" v-if="index1 == 0">
          <div>{{ scoreCardTotal.shottotal1 }}</div>
        </div>
        <div class="col" v-else>
          <div>{{ scoreCardTotal.shottotal2 }}</div>
        </div>
      </div>

      <div class="row g-0">
        <div class="col-2">
          <div>점수</div>
        </div>
        <div class="col" v-for="index5 in 9" :key="index5">
          <div>
            <span
              :class="[
                {
                  par:
                    (scoreCardScore[9 * index1 + index5 - 1]
                      ? scoreCardScore[9 * index1 + index5 - 1].score || 0
                      : 0) == 0
                      ? true
                      : false,
                },
                {
                  eagle:
                    (scoreCardScore[9 * index1 + index5 - 1]
                      ? scoreCardScore[9 * index1 + index5 - 1].score || 0
                      : 0) == -2
                      ? true
                      : false,
                },
                {
                  birdie:
                    (scoreCardScore[9 * index1 + index5 - 1]
                      ? scoreCardScore[9 * index1 + index5 - 1].score || 0
                      : 0) == -1
                      ? true
                      : false,
                },
                {
                  bogey:
                    (scoreCardScore[9 * index1 + index5 - 1]
                      ? scoreCardScore[9 * index1 + index5 - 1].score || 0
                      : 0) == 1
                      ? true
                      : false,
                },
                {
                  'd-bogey':
                    (scoreCardScore[9 * index1 + index5 - 1]
                      ? scoreCardScore[9 * index1 + index5 - 1].score || 0
                      : 0) == 2
                      ? true
                      : false,
                },
                {
                  't-bogey-over':
                    (scoreCardScore[9 * index1 + index5 - 1]
                      ? scoreCardScore[9 * index1 + index5 - 1].score || 0
                      : 0) == 3
                      ? true
                      : false,
                },
                {
                  'bg-white':
                    scoreCardScore[9 * index1 + index5 - 1] &&
                    scoreCardScore[9 * index1 + index5 - 1].score === ''
                      ? true
                      : false,
                },
              ]"
            >
              {{
                scoreCardScore[9 * index1 + index5 - 1] &&
                scoreCardScore[9 * index1 + index5 - 1].score
              }}
            </span>
          </div>
        </div>
        <div class="col" v-if="index1 == 0">
          <div>{{ scoreCardTotal.scoretotal1 }}</div>
        </div>
        <div class="col" v-else>
          <div>{{ scoreCardTotal.scoretotal2 }}</div>
        </div>
      </div>

      <div class="row g-0">
        <div class="col-2">
          <div>멀리건</div>
        </div>
        <div class="col" v-for="index6 in 9" :key="index6">
          <div>
            {{
              (scoreCardScore[9 * index1 + index6 - 1] &&
                scoreCardScore[9 * index1 + index6 - 1].mulligan) ||
              0
            }}
          </div>
        </div>
        <div class="col" v-if="index1 == 0">
          <div>{{ scoreCardTotal.mulligantotal1 }}</div>
        </div>
        <div class="col" v-else>
          <div>{{ scoreCardTotal.mulligantotal2 }}</div>
        </div>
      </div>
    </div>
    <!-- // round-record -->

    <div class="d-flex justify-content-end">
      <div class="box pc-none">
        <p class="eagle"><span></span>이글</p>
        <p class="birdie"><span></span>버디</p>
        <p class="par"><span></span>파</p>
        <p class="bogey"><span></span>보기</p>
        <p class="d-bogey"><span></span>더블보기</p>
        <p class="t-bogey-over"><span></span>트리플보기오버</p>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <div class="box m-none">
        <p class="eagle"><span></span>이글</p>
        <p class="birdie"><span></span>버디</p>
        <p class="par"><span></span>파</p>
        <p class="bogey"><span></span>보기</p>
        <p class="d-bogey"><span></span>더블보기</p>
        <p class="t-bogey-over"><span></span>트리플보기오버</p>
      </div>
    </div>
  </div>
</template>

<script>
import { myMixin } from "@/mixin/myMixin";
export default {
  name: "Scorecard",
  props: {
    scoreCardInfo: {
      type: [Array, Object],
      default: function () {
        return [
          {
            coursename: "",
            date: "",
            nickname: "",
            playtype: "",
            shopname: "",
          },
        ];
      },
    },
    scoreCardStats: {
      type: [Array, Object],
      default: function () {
        return [
          {
            maxdriver: 0,
            avgdriver: 0,
            shot: 0,
            mulligan: 0,
            avggreenhit: 0,
            farewayrate: 0,
            greenrate: 0,
          },
        ];
      },
    },
    scoreCardTotal: {
      // partotal1, partotal2, shottotal1, shottotal2, scoretotal1, scoretotal2, mulligantotal1, mulligantotal2, mulligantotal
      type: [Array, Object],
      default: function () {
        return [
          {
            partotal1: 0,
            partotal2: 0,
            shottotal1: 0,
            shottotal2: 0,
            scoretotal1: 0,
            scoretotal2: 0,
            mulligantotal1: 0,
            mulligantotal2: 0,
            mulligantotal: 0,
          },
        ];
      },
    },
    scoreCardHolePar: {
      type: [Array, Object],
      default: function () {
        return [
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
          { holeno: 0, par: 0 },
        ];
      },
    },
    scoreCardScore: {
      type: [Array, Object],
      default: function () {
        return [
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
          { shot: 0, score: 0, mulligan: 0 },
        ];
      },
    },
    // subCourseListCnt: {
    //   type: Number,
    //   default: 0,
    // },
    subCourseList: {
      type: [Array, Object],
      default: function () {
        return [
          { subcourseseq: 0, subcourseno: 0, subcoursename: "OUT" },
          { subcourseseq: 0, subcourseno: 0, subcoursename: "IN" },
        ];
      },
    },
  },
  mixins: [myMixin],
};
</script>
