<template>
  <div>
    <location />

    <div class="page p30">
      <tab />

      <div class="tab-content wrap-1200" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-2"
          role="tabpanel"
          aria-labelledby="pills-2-tab"
        >
          <h3 class="subtitle">{{ tournamentinfo.title }}</h3>
          <p class="info">{{ tournamentinfo.remark }}</p>
          <span class="date">
            <i class="material-icons">date_range</i
            >{{
              $moment(tournamentinfo.startdate).format("YYYY-MM-DD") +
              "~" +
              $moment(tournamentinfo.enddate).format("YYYY-MM-DD")
            }}
          </span>
          <div class="border-top-dgray">
            <div class="row g-0 border-bottom">
              <div
                class="col-4 col-lg-2 bg-gray row g-0 g-0 align-items-center"
              >
                <label class="d-table-cell">코스</label>
              </div>
              <div class="col-8 col-lg-10">
                <p class="d-table-cell">
                  <span v-for="(course, index) in tournamentcourse" :key="index"
                    >{{ index === 0 ? "" : ", " }}{{ course.coursename }}</span
                  >
                </p>
              </div>
            </div>

            <div class="row g-0 border-bottom">
              <div class="row g-0 col-4 col-lg-2 bg-gray align-item-center">
                <label class="d-table-cell align-self-center">서브 코스</label>
              </div>
              <div v-if="subcourselistcnt" class="col-8 col-lg-10">
                <div
                  v-for="(anc, index1) in subcourselist"
                  :key="'k_' + index1"
                  class="subcourse"
                >
                  <h6 class="pt-1">{{ anc.subcoursename }}</h6>
                  <small class="d-block text-dgray"
                    >서브 코스 {{ anc.subcourseseq }}</small
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="border-top-dgray">
            <div class="row g-0">
              <div
                class="col-lg-2 row g-0 bg-gray border-bottom align-items-center"
              >
                <label class="d-table-cell">매장</label>
              </div>
              <div class="col-lg-10 border-bottom">
                <p class="d-table-cell">
                  <span v-for="(shop, index2) in tournamentshop" :key="index2"
                    >{{ index2 === 0 ? "" : " / " }}{{ shop.shopname }}</span
                  >
                </p>
              </div>
            </div>
            <div class="row g-0">
              <div
                class="col-lg-2 row g-0 bg-gray border-bottom align-items-center"
              >
                <label class="d-table-cell">시상내역</label>
              </div>
              <div class="col-lg-10 border-bottom">
                <div class="row g-0 border-bottom">
                  <div class="col-lg-12">
                    <p class="d-table-cell">
                      {{ tournamentprizeetc.etcprize || "미등록" }}
                    </p>
                  </div>
                </div>
                <div class="row g-0 border-bottom-0">
                  <div class="col-lg-12">
                    <p class="d-table-cell">
                      <span
                        class="d-block"
                        v-for="(rank, index3) in tournamentprizerank"
                        :key="index3"
                        >{{ rank.rankno }}위 :
                        <span class="point">{{ rank.rankprize | comma }}p</span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div
                class="col-lg-2 bg-gray row g-0 align-items-center border-bottom"
              >
                <label class="d-table-cell">경기조건</label>
              </div>
              <div class="col-lg-10">
                <div class="row g-0 border-bottom-0">
                  <div
                    class="col-lg-6 d-table-cell d-flex flex-column align-items-stretch"
                  >
                    <div class="row g-0 border-bottom">
                      <div
                        class="col-4 bg-lightgray row g-0 align-items-center"
                      >
                        <label class="d-table-cell">모드</label>
                      </div>
                      <div class="col-8">
                        <p class="d-table-cell">
                          {{ tournamentdetail.mode }}
                        </p>
                      </div>
                    </div>
                    <div class="row g-0 border-bottom">
                      <div
                        class="col-4 bg-lightgray row g-0 align-items-center"
                      >
                        <label class="d-table-cell">레벨</label>
                      </div>
                      <div class="col-8">
                        <p class="d-table-cell">
                          {{ tournament_level(tournamentdetail.level) }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="row g-0 border-bottom d-flex flex-row align-items-stretch h-100"
                    >
                      <div
                        class="col-4 bg-lightgray row g-0 align-items-center"
                      >
                        <label class="d-table-cell">멀리건</label>
                      </div>
                      <div class="col-8 row align-items-center">
                        <p class="d-table-cell">
                          {{ tournamentdetail.mulligan }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 d-table-cell d-flex flex-column align-items-stretch"
                  >
                    <div class="row g-0 border-bottom">
                      <div
                        class="col-4 bg-lightgray row g-0 align-items-center"
                      >
                        <label class="d-table-cell">홀 기준</label>
                      </div>
                      <div class="col-8">
                        <p class="d-table-cell">
                          {{ tournamentdetail.hole }} 홀
                        </p>
                      </div>
                    </div>
                    <div class="row g-0 border-bottom">
                      <div
                        class="col-4 bg-lightgray row g-0 align-items-center"
                      >
                        <label class="d-table-cell">바람</label>
                      </div>
                      <div class="col-8">
                        <p class="d-table-cell">{{ wind }}</p>
                      </div>
                    </div>
                    <div class="row g-0 border-bottom">
                      <div
                        class="col-4 bg-lightgray row g-0 align-items-center"
                      >
                        <label class="d-table-cell">티 박스</label>
                      </div>
                      <div class="col-8">
                        <p class="d-table-cell">
                          남성 : {{ teemale }}<br />
                          여성 : {{ teefemale }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="row g-0 border-bottom-0">
                  <div class="col-lg-3 bg-gray row align-items-center">
                    <label class="d-table-cell">Handicap(No. of Play)</label>
                  </div>
                  <div class="col-lg-9">
                    <p class="d-table-cell">
                      {{ tournamentdetail.playhandicap }}
                    </p>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <!--         <div class="start-m row center-btn">
          <div class="col-lg-4 d-table m-auto">
            <button
              class="btn btn-primary btn-xl"
              type="button"
              @click="click_list()"
            >
              목록
            </button>
          </div>
        </div> -->

        <div class="btn-bottom-wrapper">
          <b-button
            size="xl"
            variant="outline-primary"
            aria-label="목록"
            @click="click_list()"
            >목록</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import ax from "@/api/membership";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "MyTournamentsView",
  components: {
    location,
    tab,
  },
  data() {
    return {
      tournamentno: this.$route.params.id,
      tournamentinfo: [
        {
          title: "",
          remark: "",
          startdate: "",
          enddate: "",
        },
      ],
      tournamentcourse: [],
      tournamentshop: [],
      tournamentprizeetc: [
        {
          etcprize: "",
        },
      ],
      tournamentprizerank: [],
      tournamentdetail: [
        {
          mode: "",
          hole: "",
          level: "",
          wind: "",
          mulligan: "",
          teeboxman: "",
          teeboxwoman: "",
          playhandicap: "",
        },
      ],
      wind: "",
      teemale: "",
      teefemale: "",
      subcourselist: [],
      subcourselistcnt: 0,
    };
  },
  methods: {
    get_membertournamentcondition() {
      ax.get_membertournamentcondition(this.tournamentno, (flag, data) => {
        if (flag) {
          this.tournamentinfo = data.tournamentinfo;
          this.tournamentcourse = data.tournamentcourse;
          this.tournamentshop = data.tournamentshop;
          this.tournamentprizeetc = data.tournamentprizeetc;
          this.tournamentprizerank = data.tournamentprizerank;
          this.tournamentdetail = data.tournamentdetail;
          this.subcourselist = data.subcourselist;
          this.subcourselistcnt = data.subcourselistcnt;
          this.change_wind(this.tournamentdetail.wind);
          this.change_male(this.tournamentdetail.teeboxman);
          this.change_female(this.tournamentdetail.teeboxwoman);
        } else {
          alert(data);
        }
      });
    },
    click_list() {
      this.$router.push({
        name: "MyTournaments",
        query: {
          startdate: this.$route.query.startdate,
          enddate: this.$route.query.enddate,
        },
      });
    },
    change_wind(wind) {
      if (wind === "0") {
        this.wind = "무풍";
      } else if (wind === "1") {
        this.wind = "약풍";
      } else if (wind === "2") {
        this.wind = "중풍";
      } else if (wind === "3") {
        this.wind = "강풍";
      } else if (wind === "4") {
        this.wind = "자동";
      } else {
        this.wind = wind;
      }
    },
    change_male(teeboxman) {
      if (teeboxman === "1") {
        this.teemale = "프로 (블루)";
      } else if (teeboxman === "2") {
        this.teemale = "일반 (화이트)";
      } else if (teeboxman === "3") {
        this.teemale = "레이디 (레드)";
      } else {
        this.teemale = teeboxman;
      }
    },
    change_female(teeboxwoman) {
      if (teeboxwoman === "1") {
        this.teefemale = "프로 (블루)";
      } else if (teeboxwoman === "2") {
        this.teefemale = "일반 (화이트)";
      } else if (teeboxwoman === "3") {
        this.teefemale = "레이디 (레드)";
      } else {
        this.teefemale = teeboxwoman;
      }
    },
  },
  created() {
    this.get_membertournamentcondition();
  },
  mixins: [myMixin],
};
</script>
