<template>
  <div>
    <location />

    <div class="p20 page">
      <tab />

      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-2"
          role="tabpanel"
          aria-labelledby="pills-2-tab"
        >
          <div class="wrap-1200">
            <h3 class="subtitle">{{ tournamentname }}</h3>
          </div>
          <div class="wrap-1200">
            <b-form @submit="onSubmit">
              <div class="gender-search">
                <div>
                  <select
                    class="border-right-0"
                    aria-label=".form-select-lg example"
                    name="gender"
                    v-model="searchData.gender"
                  >
                    <option value="0" :selected="searchData.gender === 0">
                      전체
                    </option>
                    <option value="1" :selected="searchData.gender === 1">
                      남성
                    </option>
                    <option value="2" :selected="searchData.gender === 2">
                      여성
                    </option>
                  </select>
                  <input
                    class="btn btn-primary btn-lg search"
                    type="submit"
                    value=""
                  />
                </div>
                <ul>
                  <li>
                    <a
                      :class="{ on: searchData.scoretype == 1 }"
                      @click="change_type(1)"
                    >
                      스트로크
                    </a>
                  </li>
                  <li>
                    <a
                      :class="{ on: searchData.scoretype == 2 }"
                      @click="change_type(2)"
                      >신페리오</a
                    >
                  </li>
                  <li>
                    <a
                      :class="{ on: searchData.scoretype == 3 }"
                      @click="change_type(3)"
                      >넷스코어</a
                    >
                  </li>
                </ul>
              </div>
            </b-form>
          </div>

          <div class="row g-0 wrap-1200 myrank">
            <div class="col-6 col-lg-3">
              <ul class="box">
                <li>
                  <span class="rounded-circle bg-purple">
                    <i class="material-icons">military_tech</i>
                  </span>
                </li>
                <li>
                  <h2>
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.rank
                    }}<small>/{{ this.total_cnt || 0 }}</small>
                  </h2>
                  <p>나의 순위</p>
                </li>
              </ul>
            </div>

            <div class="col-6 col-lg-3">
              <ul class="box">
                <li>
                  <span class="rounded-circle bg-pink">
                    <i class="material-icons">scoreboard</i>
                  </span>
                </li>
                <li>
                  <h2 v-if="searchData.scoretype == '1'">
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.strokelastscore
                    }}
                  </h2>
                  <h2 v-if="searchData.scoretype == '2'">
                    {{
                      (rankInfo &&
                        rankInfo.tournamentcourse &&
                        rankInfo.tournamentcourse.newperioscore) | toFixed
                    }}
                  </h2>
                  <h2 v-if="searchData.scoretype == '3'">
                    {{
                      (rankInfo &&
                        rankInfo.tournamentrankinfo &&
                        rankInfo.tournamentrankinfo.avgnetscore) | toFixed
                    }}
                  </h2>
                  <p v-if="searchData.scoretype == '1'">코스 성적</p>
                  <p v-if="searchData.scoretype == '2'">코스 성적(신페리오)</p>
                  <p v-if="searchData.scoretype == '3'">코스 성적(핸디)</p>
                </li>
              </ul>
            </div>

            <div class="col-6 col-lg-3">
              <ul class="box">
                <li>
                  <span class="rounded-circle bg-cyan">
                    <i class="material-icons">emoji_events</i>
                  </span>
                </li>
                <li>
                  <h2 v-if="searchData.scoretype == '1'">
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.strokelastscore
                    }}
                  </h2>
                  <h2 v-if="searchData.scoretype == '2'">
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.newperiolastscore
                    }}
                  </h2>
                  <h2 v-if="searchData.scoretype == '3'">
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.avgnetscore | toFixed
                    }}
                  </h2>
                  <p v-if="searchData.scoretype == '1'">최종 성적</p>
                  <p v-if="searchData.scoretype == '2'">최종 성적(신페리오)</p>
                  <p v-if="searchData.scoretype == '3'">최종 성적(핸디)</p>
                </li>
              </ul>
            </div>

            <div class="col-6 col-lg-3">
              <ul class="box">
                <li>
                  <span class="rounded-circle bg-orange">
                    <i class="material-icons">flag</i>
                  </span>
                </li>
                <li>
                  <h2>
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.playcnt
                    }}
                  </h2>
                  <p>참가 횟수</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="page wrap-1200 mt-0" id="mytour-tabContent">
            <div
              class="tab-pane fade show active"
              id="mytour-1"
              role="tabpanel"
              aria-labelledby="mytour-1-tab"
            >
              <table
                class="board table wrap-1200"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr v-if="searchData.scoretype === 1">
                    <th class="col-auto d-lg-table-cell">순위</th>
                    <th class="col-auto d-lg-table-cell">닉네임</th>
                    <th class="col-auto d-none d-lg-table-cell">코스 성적</th>
                    <th class="col-auto d-none d-lg-table-cell">최종 성적</th>
                    <th class="col-auto d-none d-lg-table-cell">참가 횟수</th>
                    <!-- <th class="col-auto d-none d-lg-table-cell">
                      환산 점수
                    </th> -->
                    <th class="th-arrow">&nbsp;</th>
                  </tr>
                  <tr v-if="searchData.scoretype === 2">
                    <th class="col-auto d-lg-table-cell">순위</th>
                    <th class="col-auto d-lg-table-cell">닉네임</th>
                    <th class="col-auto d-none d-lg-table-cell">
                      코스 성적<br />(스트로크/신페리오)
                    </th>
                    <th class="col-auto d-none d-lg-table-cell">
                      최종 성적<br />(신페리오)
                    </th>
                    <th class="col-auto d-none d-lg-table-cell">참가 횟수</th>
                    <!-- <th class="col-auto d-none d-lg-table-cell">
                      환산 점수
                    </th> -->
                    <th class="th-arrow">&nbsp;</th>
                  </tr>
                  <tr v-if="searchData.scoretype === 3">
                    <th class="col-auto d-lg-table-cell">순위</th>
                    <th class="col-auto d-lg-table-cell">닉네임</th>
                    <th class="col-auto d-none d-lg-table-cell">
                      코스 성적<br />(스트로크/핸디)
                    </th>
                    <th class="col-auto d-none d-lg-table-cell">
                      최종 성적<br />(핸디)
                    </th>
                    <th class="col-auto d-none d-lg-table-cell">참가 횟수</th>
                    <!-- <th class="col-auto d-none d-lg-table-cell">환산 점수</th> -->
                    <th class="th-arrow"></th>
                  </tr>
                </thead>
                <tbody v-if="data_yn && searchData.scoretype === 1">
                  <template v-for="(item, index) in items">
                    <tr
                      class="btn1"
                      @click="toggle_tr(index)"
                      :key="index + 's'"
                    >
                      <td class="col-auto d-lg-table-cell">
                        {{ item.tournamentrankinfo.rank }}
                      </td>
                      <td class="col-auto d-lg-table-cell pointer more">
                        <MemberInfo
                          :nickname="item.tournamentrankinfo.nickname"
                        />
                      </td>
                      <td class="col-auto d-none d-lg-table-cell">
                        <template
                          v-for="(course, index_n) in item.tournamentcourse"
                        >
                          <span :key="index_n + 'm1'">{{
                            course.coursename
                          }}</span>
                          &nbsp;
                          <span :key="index_n + 'm2'">{{
                            course.strokescore
                          }}</span>
                        </template>
                      </td>
                      <td class="col-auto d-none d-lg-table-cell">
                        {{
                          item.tournamentrankinfo &&
                          item.tournamentrankinfo.strokelastscore
                        }}
                      </td>
                      <td class="col-auto d-none d-lg-table-cell">
                        {{ item.tournamentrankinfo.playcnt }}
                      </td>
                      <!-- <td class="col-auto d-none d-lg-table-cell">
                        {{ item.tournamentrankinfo.strokelastscore }}
                      </td> -->
                      <td class="td-arrow">
                        <img
                          src="/img/arrow_none_collapsed.png"
                          :class="{ 'btn-toggle-arrow': view_hide === index }"
                        />
                      </td>
                    </tr>
                    <tr
                      class="col-auto d-lg-none d-md-tabel-cell"
                      :class="{ show: view_hide === index }"
                      :key="index + 'r'"
                      v-if="view_hide === index"
                    >
                      <td class="align-left bg-white" colspan="8">
                        <!--
                        <router-link
                          :to="{
                            name: 'TournamentMember',
                            params: { playerno: item.tournamentrankinfo.playerno },
                          }"
                          class="btn1 btn_view"
                        >
                          <img src="/img/ico_edit.png" />
                        </router-link>
                        -->
                        <div class="layer1">
                          <div class="row g-0">
                            <div class="col-6">코스 성적</div>
                            <div class="col-6">
                              <template
                                v-for="(
                                  course, index_nm
                                ) in item.tournamentcourse"
                              >
                                {{ index_nm > 0 ? " / " : "" }}
                                <span :key="index_nm + 'm'">{{
                                  course.coursename
                                }}</span>
                              </template>
                              <br />
                              <template
                                v-for="(
                                  course, index_cm
                                ) in item.tournamentcourse"
                              >
                                {{ index_cm > 0 ? " / " : "" }}
                                <span :key="index_cm">{{
                                  course.strokescore
                                }}</span>
                              </template>
                            </div>
                          </div>
                          <div class="row g-0">
                            <div class="col-6">최종 성적</div>
                            <div class="col-6">
                              {{
                                item.tournamentrankinfo &&
                                item.tournamentrankinfo.strokelastscore
                              }}
                            </div>
                          </div>
                          <div class="row g-0">
                            <div class="col-6">참가 횟수</div>
                            <div class="col-6">
                              {{ item.tournamentrankinfo.playcnt }}
                            </div>
                          </div>
                          <!-- <div class="row g-0">
                            <div class="col-6">환산 점수</div>
                            <div class="col-6">
                              {{ item.tournamentrankinfo.strokelastscore }}
                            </div>
                          </div> -->
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tbody v-else-if="data_yn && searchData.scoretype === 2">
                  <template v-for="(item, index) in items">
                    <tr
                      class="btn1"
                      @click="toggle_tr(index)"
                      :key="index + 'n'"
                    >
                      <td class="col-auto d-lg-table-cell">
                        {{ item.tournamentrankinfo.rank }}
                      </td>
                      <td class="col-auto d-lg-table-cell">
                        <MemberInfo
                          :nickname="item.tournamentrankinfo.nickname"
                        />
                      </td>
                      <td class="col-auto d-none d-lg-table-cell">
                        <template
                          v-for="(course, index_n) in item.tournamentcourse"
                        >
                          <span :key="index_n + 'l1'">{{
                            course.coursename
                          }}</span>
                        </template>
                        <br />
                        <template
                          v-for="(course, index_c) in item.tournamentcourse"
                        >
                          <span :key="index_c + 'l2'"
                            >({{ course.strokescore }}</span
                          >
                        </template>
                        /
                        <template
                          v-for="(course, index_ns) in item.tournamentcourse"
                        >
                          <span :key="index_ns + 'l3'">
                            {{ course.newperioscore }})
                          </span>
                        </template>
                      </td>
                      <td class="col-auto d-none d-lg-table-cell">
                        {{
                          (item.tournamentrankinfo &&
                            item.tournamentrankinfo.newperiolastscore) ||
                          0
                        }}
                      </td>
                      <td class="col-auto d-none d-lg-table-cell">
                        {{ item.tournamentrankinfo.playcnt }}
                      </td>
                      <!-- <td class="col-auto d-none d-lg-table-cell">
                        {{ item.tournamentrankinfo.newperiolastscore }}
                      </td> -->
                      <td class="td-arrow">
                        <img
                          src="/img/arrow_none_collapsed.png"
                          :class="{ 'btn-toggle-arrow': view_hide === index }"
                        />
                      </td>
                    </tr>
                    <tr
                      class="col-auto d-lg-none d-md-tabel-cell"
                      :class="{ show: view_hide === index }"
                      :key="index + 'r'"
                      v-if="view_hide === index"
                    >
                      <td class="align-left bg-white" colspan="8">
                        <!--
                        <router-link
                          :to="{
                            name: 'TournamentMember',
                            params: { playerno: item.tournamentrankinfo.playerno },
                          }"
                          class="btn1 btn_view"
                        >
                          <img src="/img/ico_edit.png" />
                        </router-link>
                        -->
                        <div class="layer1">
                          <div class="row g-0">
                            <div class="col-6">
                              코스 성적<br />(스트로크/신페리오)
                            </div>
                            <div class="col-6">
                              <template
                                v-for="(
                                  course, index_nm
                                ) in item.tournamentcourse"
                              >
                                {{ index_nm > 0 ? " / " : "" }}
                                <span :key="index_nm + 'm'">{{
                                  course.coursename
                                }}</span>
                              </template>
                              <br />
                              <template
                                v-for="(
                                  course, index_cm
                                ) in item.tournamentcourse"
                              >
                                ({{ index_cm > 0 ? " / " : "" }}
                                <span :key="index_cm">{{
                                  course.strokescore
                                }}</span>
                              </template>
                              /
                              <template
                                v-for="(
                                  course, index_nm
                                ) in item.tournamentcourse"
                              >
                                {{ index_nm > 0 ? " / " : "" }}
                                <span :key="index_nm + 'nm'"
                                  >{{ course.newperioscore }})</span
                                >
                              </template>
                            </div>
                          </div>
                          <div class="row g-0">
                            <div class="col-6">최종 성적<br />(신페리오)</div>
                            <div class="col-6">
                              {{
                                (item.tournamentrankinfo &&
                                  item.tournamentrankinfo.avg_newperio) ||
                                0
                              }}
                            </div>
                          </div>
                          <div class="row g-0">
                            <div class="col-6">참가 횟수</div>
                            <div class="col-6">
                              {{ item.tournamentrankinfo.playcnt }}
                            </div>
                          </div>
                          <!-- <div class="row g-0">
                            <div class="col-6">환산 점수</div>
                            <div class="col-6">
                              {{ item.tournamentrankinfo.newperiolastscore }}
                            </div>
                          </div> -->
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>

                <tbody v-else-if="data_yn && searchData.scoretype === 3">
                  <template v-for="(item, index) in items">
                    <tr
                      class="btn1"
                      @click="toggle_tr(index)"
                      :key="index + 'n'"
                    >
                      <td class="col-auto d-lg-table-cell">
                        {{ item.tournamentrankinfo.rank }}
                      </td>
                      <td class="col-auto d-lg-table-cell">
                        <MemberInfo
                          :nickname="item.tournamentrankinfo.nickname"
                        />
                      </td>
                      <td class="col-auto d-none d-lg-table-cell">
                        <template
                          v-for="(course, index_n) in item.tournamentcourse"
                        >
                          <span :key="index_n + 'l1'">{{
                            course.coursename
                          }}</span>
                        </template>
                        <br />
                        ({{
                          item.tournamentrankinfo &&
                          item.tournamentrankinfo.strokelastscore
                        }}
                        /
                        {{
                          (item.tournamentrankinfo &&
                            item.tournamentrankinfo.avgnetscore) | toFixed
                        }})
                      </td>
                      <td class="col-auto d-none d-lg-table-cell">
                        {{
                          (item.tournamentrankinfo &&
                            item.tournamentrankinfo.avgnetscore) | toFixed
                        }}
                      </td>
                      <td class="col-auto d-none d-lg-table-cell">
                        {{ item.tournamentrankinfo.playcnt }}
                      </td>
                      <!-- <td class="col-auto d-none d-lg-table-cell">
                        {{ item.tournamentrankinfo.newperiolastscore }}
                      </td> -->
                      <td class="td-arrow">
                        <img src="/img/arrow_none_collapsed.png" />
                      </td>
                    </tr>
                    <tr
                      class="col-auto d-lg-none d-md-tabel-cell"
                      :class="{ show: view_hide === index }"
                      :key="index + 'r'"
                      v-if="view_hide === index"
                    >
                      <td class="align-left fill-white" colspan="8">
                        <!--
                        <router-link
                          :to="{
                            name: 'TournamentMember',
                            params: { playerno: item.tournamentrankinfo.playerno },
                          }"
                          class="btn1 btn_view"
                        >
                          <img src="/img/ico_edit.png" />
                        </router-link>
                        -->
                        <div class="layer1">
                          <div class="row">
                            <div class="col-6">
                              코스 성적<br />(스트로크/핸디)
                            </div>
                            <div class="col-6">
                              <template
                                v-for="(
                                  course, index_n
                                ) in item.tournamentcourse"
                              >
                                <span :key="index_n + 'l1'">{{
                                  course.coursename
                                }}</span>
                              </template>
                              <br />
                              ({{
                                (item.avgscore && item.avgscore.strokescore)
                                  | toFixed
                              }}
                              /
                              {{
                                (item.tournamentcourse &&
                                  item.tournamentcourse.avgnetscore) | toFixed
                              }})
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">최종 성적<br />(핸디)</div>
                            <div class="col-6">
                              {{
                                (item.avgscore && item.avgscore.avgnetscore)
                                  | toFixed
                              }}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">참가 횟수</div>
                            <div class="col-6">
                              {{ item.tournamentrankinfo.playcnt }}
                            </div>
                          </div>
                          <!-- <div class="row">
                            <div class="col-6">Best Score<br /></div>
                            <div class="col-6">
                              {{ item.tournamentrankinfo.newperiolastscore }}
                            </div>
                          </div> -->
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="10" class="text-center">데이터가 없습니다.</td>
                  </tr>
                </tbody>
              </table>
              <b-pagination-nav
                :link-gen="linkGen"
                :number-of-pages="page_total"
                v-model="searchData.pageno"
                use-router
                aria-label="Page navigation example"
                class="mt-3"
                v-if="page_total > 1"
                first-class="prev"
                prev-class="prev"
                next-class="next"
                last-class="next"
                hide-ellipsis
                limit="10"
              >
                <template #first-text>
                  <i class="material-icons">keyboard_double_arrow_left</i>
                </template>
                <template #prev-text>
                  <i class="material-icons">navigate_before</i>
                </template>
                <template #next-text>
                  <i class="material-icons">navigate_next</i>
                </template>
                <template #last-text>
                  <i class="material-icons">keyboard_double_arrow_right</i>
                </template>
                <template #page="{ page, active }">
                  <a v-if="active">{{ page }}</a>
                  <a v-else>{{ page }}</a>
                </template>
              </b-pagination-nav>

              <!--               <div class="wrap-1200 start-m row g-0 center-btn">
                <div class="col-lg-4 d-table m-auto">
                  <button
                    class="btn btn-primary btn-xl"
                    type="button"
                    @click="
                      $router.push({ name: 'MyTournaments' }).catch(() => {})
                    "
                  >
                    목록
                  </button>
                </div>
              </div> -->

              <div class="btn-bottom-wrapper">
                <b-button
                  size="xl"
                  variant="outline-primary"
                  aria-label="목록"
                  @click="
                    $router.push({ name: 'MyTournaments' }).catch(() => {})
                  "
                  >목록</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import MemberInfo from "@/components/membership/MemberInfo";
import ax from "@/api/membership";
import tax from "@/api/tournament";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "MyTournamentsRanking",
  components: {
    location,
    tab,
    MemberInfo,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        tournamentno: this.$route.params.id,
        scoretype: parseInt(this.$route.query.scoretype) || 1,
        gender: parseInt(this.$route.query.gender) || 0,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      rankInfo: null,
      view_hide: null,
      tournamentname: "",
      member: {
        playerno: this.$route.params.playerno,
        playerbasicinfo: [],
        playerpalyinfo: [],
      },
    };
  },
  methods: {
    get_membertournamentranklist() {
      ax.get_membertournamentranklist(
        this.searchData,
        (flag, data, code = "") => {
          if (flag) {
            if (data.tournamentranklistcnt <= 0) {
              this.data_yn = false;
              return false;
            } else {
              this.data_yn = true;
            }
            this.total_cnt = data.tournamentranklistcnt;
            this.page_total = Math.ceil(data.tournamentranklistcnt / this.rows);
            this.items = data.tournamentranklist;
            this.rankInfo = data.player;

            this.tournamentname = data.tournamentname;
          } else {
            this.data_yn = false;
            this.page_total = 0;
            if (code == "300") {
              this.$notify({
                group: "alert",
                type: "warn",
                text: data,
                duration: 5000,
                speed: 1000,
              });
            }
            // alert(data);
          }
        }
      );
    },
    linkGen(pageNum) {
      return pageNum === 1
        ? `?scoretype=${this.searchData.scoretype}&gender=${this.searchData.gender}`
        : `?page=${pageNum}&scoretype=${this.searchData.scoretype}&gender=${this.searchData.gender}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            scoretype: this.searchData.scoretype,
            gender: this.searchData.gender,
          },
        })
        .catch(() => {});
    },
    change_type(type) {
      this.searchData.scoretype = type;
      this.searchData.pageno = 1;
      this.view_hide = null;
      this.$router
        .push({
          name: "MyTournamentsRanking",
          params: { id: this.searchData.tournamentno },
          query: {
            scoretype: this.searchData.scoretype,
            gender: this.searchData.gender,
          },
        })
        .catch(() => {});
    },
    getMemberInfo(playerno) {
      tax.get_playerinfo(playerno, (flag, data) => {
        if (flag) {
          this.member.playerno = playerno;
          this.member.playerbasicinfo = data.playerbasicinfo;
          this.member.playerpalyinfo = data.playerpalyinfo;
          this.$refs["memberInfoModal"].modalOuterStyle.zIndex = 9999;
          this.$refs["memberInfoModal"].show();
        } else {
          alert(data);
        }
      });
    },
  },
  created() {
    this.get_membertournamentranklist();
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.get_membertournamentranklist();
    },
  },
  mixins: [myMixin],
};
</script>
