<template>
  <div>
    <location />

    <div class="p12 page">
      <account-tab />

      <div class="tab-content wrap-1200" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        >
          <div class="row g-0">
            <div class="col-lg-6">
              <h5>단골매장을 선택해주세요.</h5>
            </div>
            <div class="col-lg-6">
              <b-form @submit="onSubmit">
                <div class="select-search d-flex">
                  <select
                    class="border-right-0"
                    aria-label=".border-right-0 example"
                    name="searchtype"
                    v-model="searchData.searchtype"
                  >
                    <option value="1">지역명</option>
                    <option value="2">매장명</option>
                  </select>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      name="searchname"
                      v-model="searchData.searchname"
                    />
                    <input
                      class="btn btn-primary search"
                      type="submit"
                      value=""
                    />
                  </div>
                </div>
              </b-form>
            </div>
          </div>

          <table
            class="board table"
            id="dataTable"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr>
                <th class="col-auto d-lg-table-cell">지역</th>
                <th class="col-auto d-lg-table-cell text-center">매장명</th>
                <th class="col-auto d-none d-lg-table-cell">전화번호</th>
                <th class="col-auto d-none d-lg-table-cell">주소</th>
                <th class="col-auto d-none d-lg-table-cell">등록일</th>
                <th class="col-auto d-none d-lg-table-cell">선택</th>
                <th class="th-arrow">&nbsp;</th>
              </tr>
            </thead>
            <tbody v-if="data_yn">
              <template v-for="(item, index) in items">
                <tr class="btn1" @click="toggle_tr(index)" :key="index">
                  <td class="col-auto d-lg-table-cell">{{ item.area }}</td>
                  <td class="col-auto d-lg-table-cell">
                    <router-link
                      :to="{
                        name: 'FavoriteLocationInfo',
                        params: { shopno: item.shopno },
                        query: {
                          searchtype: searchData.searchtype,
                          searchname: searchData.searchname,
                        },
                      }"
                      class="more"
                    >
                      {{ item.shopname }}
                    </router-link>
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    {{ item.phone }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    {{ item.address }}
                  </td>

                  <td
                    class="col-auto d-none d-lg-table-cell date"
                    v-if="item.shopno === shopInfo.owner_fk"
                  >
                    {{ shopInfo.regdate | GMTtuUTC }}
                  </td>
                  <td v-else class="col-auto d-none d-lg-table-cell"></td>
                  <td class="col-auto d-none d-lg-table-cell">
                    <input
                      type="checkbox"
                      class="form-check-input me-0"
                      :checked="item.shopno === shopInfo.owner_fk"
                      @click="shop_choose(item.shopno)"
                    />
                  </td>
                  <td class="td-arrow">
                    <img
                      src="/img/arrow_none_collapsed.png"
                      :class="{ 'btn-toggle-arrow': view_hide === index }"
                    />
                  </td>
                </tr>
                <tr
                  class="col-auto d-lg-none d-md-tabel-cell"
                  :class="{ show: view_hide === index }"
                  :key="index + 'r'"
                  v-if="view_hide === index"
                >
                  <td class="align-left bg-white" colspan="6">
                    <!-- 
                    <router-link
                      :to="{
                        name: 'FavoriteLocationInfo',
                        params: { shopno: item.shopno },
                        query: {
                          searchtype: searchData.searchtype,
                          searchname: searchData.searchname
                        }
                      }"
                      class="btn1 btn_view"
                      ><img src="/img/ico_edit.png" />
                    </router-link>
                    -->
                    <div class="layer1">
                      <div class="row g-0">
                        <div class="col-4">전화번호</div>
                        <div class="col">{{ item.phone }}</div>
                      </div>
                      <div class="row g-0">
                        <div class="col-4">주소</div>
                        <div class="col">{{ item.address }}</div>
                      </div>
                      <div class="row g-0">
                        <div class="col-4">등록일</div>
                        <div
                          class="col date"
                          v-if="item.shopno === shopInfo.owner_fk"
                        >
                          {{ shopInfo.regdate | GMTtuUTC }}
                        </div>
                      </div>
                      <div class="row g-0">
                        <div class="col-4">선택</div>
                        <div class="col">
                          <input
                            type="checkbox"
                            class="form-check-input me-0"
                            :checked="item.shopno === shopInfo.owner_fk"
                            @click="shop_choose(item.shopno)"
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="6">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation example"
        class="mt-3"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import AccountTab from "@/components/membership/AccountTab";
import ax from "@/api/membership";

export default {
  name: "FavoriteLocation",
  components: {
    location,
    AccountTab,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        searchtype: this.$route.query.searchtype || 1,
        searchname: this.$route.query.searchname || "",
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      shopInfo: {},
      view_hide: null,
      change_flag: null,
      flag_cnt: 0,
      flags: [],
    };
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.get_memberprefershop();
    },
  },
  methods: {
    get_memberprefershop() {
      ax.get_memberprefershop(this.searchData, (flag, data) => {
        if (flag) {
          if (data.shoplistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.shoplistcnt;
          this.page_total = Math.ceil(data.shoplistcnt / this.rows);
          this.items = data.shoplist;
          this.shopInfo = data.shopinfo || [];
          this.flag_cnt = 0;
          this.flags = [];
          // for (let i = 0; i < this.shopInfo.length; i++) {
          //   if (this.items[i].flag === "1") this.flag_cnt += 1;
          //   this.flags.push(this.items[i].flag);
          // }
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router.push({
        query: {
          searchtype: this.searchData.searchtype,
          searchname: this.searchData.searchname,
        },
      });
    },
    linkGen(pageNum) {
      return pageNum === 1
        ? `?searchname=${this.searchData.searchname}&searchtype=${this.searchData.searchtype}`
        : `?page=${pageNum}&searchname=${this.searchData.searchname}&searchtype=${this.searchData.searchtype}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    shop_choose(shopno) {
      event.preventDefault();
      this.change_flag = this.shopInfo.owner_fk == shopno ? true : false;
      if (this.change_flag && this.shopInfo.owner_fk) {
        alert("같은 장소를 선택하셨습니다.");
        this.get_memberprefershop();
        return false;
      }
      if (
        confirm(
          "즐겨찾는 위치로 변경하면 위치 벅스가 재설정됩니다..\n" +
            "진행 하시겠습니까?"
        )
      ) {
        ax.memberprefershopchoose(shopno, 1, (flag, data) => {
          alert(data);
          this.get_memberprefershop();
        });
      }
    },
  },
  created() {
    this.get_memberprefershop();
  },
};
</script>

<style scoped>
#sub .p12 .select-search p {
  line-height: 48px;
  text-align: right;
  display: block;
}
@media (max-width: 992px) {
  #sub .p12 .select-search p {
    line-height: 1;
    margin-bottom: 25px;
    text-align: left;
  }
}
</style>