<template>
  <div>
    <location />

    <div class="p22 page">
      <tab />

      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-3"
          role="tabpanel"
          aria-labelledby="pills-3-tab"
        >
          <div class="wrap-1200">

            <b-form @submit="onSubmit">
              <div class="date-select d-flex flex-column flex-lg-row">

                <div class="input-date-box flex-fill d-flex">
                  <input
                    type="date"
                    class="mw-100"
                    name="startdate"
                    v-model="searchData.startdate"
                  />
                  <input
                    type="date"
                    class="mw-100"
                    name="enddate"
                    v-model="searchData.enddate"
                  />
                </div><!-- //input-date-box -->

                <div class="input-group flex-nowrap flex-fill mt-2 mt-lg-0">
                  <select
                    class="mw-100 mt-0"
                    aria-label="이벤트 선택"
                    name="type"
                    v-model="searchData.type"
                  >
                    <option value="0" :selected="searchData.type === 0">
                      전체
                    </option>
                    <option value="1" :selected="searchData.type === 1">
                      홀인원
                    </option>
                    <option value="2" :selected="searchData.type === 2">
                      알바트로스
                    </option>
                    <option value="3" :selected="searchData.type === 3">
                      이글-파5
                    </option>
                    <option value="4" :selected="searchData.type === 4">
                      버디-파5
                    </option>
                    <option value="5" :selected="searchData.type === 5">
                      파-파5
                    </option>
                    <option value="6" :selected="searchData.type === 6">
                      이글-파4
                    </option>
                    <option value="7" :selected="searchData.type === 7">
                      버디-파4
                    </option>
                    <option value="8" :selected="searchData.type === 8">
                      파-파4
                    </option>
                    <option value="9" :selected="searchData.type === 9">
                      롱기스트
                    </option>
                    <option value="10" :selected="searchData.type === 10">
                      니어 핀
                    </option>
                  </select>
                  <input
                    class="btn btn-primary btn-lg search"
                    type="submit"
                    value=""
                  />
                </div><!-- //input-group -->

              </div>
            </b-form>
          </div>
          <table
            class="board table wrap-1200"
            id="dataTable"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr>
                <th class="col-auto d-lg-table-cell">제목</th>
                <th class="col-auto d-none d-lg-table-cell">구분</th>
                <th class="col-auto d-none d-lg-table-cell">당첨일</th>
                <th class="col-auto d-none d-lg-table-cell">코스</th>
                <th class="col-auto d-none d-lg-table-cell">홀</th>
                <th class="col-auto d-none d-lg-table-cell">벅스</th>
                <th class="col-auto d-lg-table-cell">매장</th>
                <th class="th-arrow">&nbsp;</th>
              </tr>
            </thead>
            <tbody v-if="data_yn">
              <template v-for="(item, index) in items">
                <tr class="btn1" @click="toggle_tr(index)" :key="index">
                  <td class="col-auto d-lg-table-cell">
                    {{ item.title }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    {{ event_type(item.type) }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell date">
                    {{ item.date | GMTtuUTCTIME }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    {{ item.coursename }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    {{ item.hole + " 홀" }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell point">
                    {{ item.point | comma }} B
                  </td>

                  <td class="col-auto d-lg-table-cell">
                    {{ item.shopname }}
                  </td>
                  <td class="td-arrow">
                    <img
                      src="/img/arrow_none_collapsed.png"
                      :class="{ 'btn-toggle-arrow': view_hide === index }"
                    />
                  </td>
                </tr>
                <tr
                  class="col-auto d-lg-none d-md-tabel-cell"
                  :class="{ show: view_hide === index }"
                  :key="index + 'r'"
                  v-if="view_hide === index"
                >
                  <td class="align-left bg-white" colspan="7">
                    <div class="layer1">
                      <div class="row g-0">
                        <div class="col">구분</div>
                        <div class="col-9">{{ event_type(item.type) }}</div>
                      </div>
                      <div class="row g-0">
                        <div class="col">당첨일</div>
                        <div class="col-9 date">
                          {{ item.date | GMTtuUTCTIME }}
                        </div>
                      </div>
                      <div class="row g-0">
                        <div class="col">코스</div>
                        <div class="col-9">
                          {{ item.coursename }}
                        </div>
                      </div>
                      <div class="row g-0">
                        <div class="col">홀</div>
                        <div class="col-9">
                          {{ item.hole + "H" }}
                        </div>
                      </div>
                      <div class="row g-0">
                        <div class="col">벅스</div>
                        <div class="col-9 point">{{ item.point | comma }}B</div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7" class="text-center">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="page_total"
            v-model="searchData.pageno"
            use-router
            aria-label="Page navigation example"
            class="mt-3"
            v-if="page_total > 1"
            first-class="prev"
            prev-class="prev"
            next-class="next"
            last-class="next"
            hide-ellipsis
            limit="10"
          >
            <template #first-text>
              <i class="material-icons">keyboard_double_arrow_left</i>
            </template>
            <template #prev-text>
              <i class="material-icons">navigate_before</i>
            </template>
            <template #next-text>
              <i class="material-icons">navigate_next</i>
            </template>
            <template #last-text>
              <i class="material-icons">keyboard_double_arrow_right</i>
            </template>
            <template #page="{ page, active }">
              <a v-if="active">{{ page }}</a>
              <a v-else>{{ page }}</a>
            </template>
          </b-pagination-nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import ax from "@/api/membership";
import { myMixin } from "@/mixin/myMixin";
import "@/filter/common";

export default {
  name: "MyEvent",
  components: {
    location,
    tab,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        startdate:
          this.$route.query.startdate ||
          this.$moment(new Date().setMonth(new Date().getMonth() - 1)).format(
            "YYYY-MM-DD"
          ),
        enddate:
          this.$route.query.enddate ||
          this.$moment(new Date()).format("YYYY-MM-DD"),
        type: this.$route.query.type || 0,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      view_hide: null,
    };
  },
  mixins: [myMixin],
  methods: {
    get_membereventwinninglist() {
      ax.get_membereventwinninglist(
        this.searchData,
        (flag, data, code = "") => {
          if (flag) {
            if (data.eventwvinninglistcnt <= 0) {
              this.data_yn = false;
              return false;
            } else {
              this.data_yn = true;
            }
            this.total_cnt = data.eventwvinninglistcnt;
            this.page_total = Math.ceil(data.eventwvinninglistcnt / this.rows);
            this.items = data.eventwvinninglist;
          } else {
            this.data_yn = false;
            this.page_total = 0;
            if (code == "300") {
              // alert(data);
              this.$notify({
                group: "alert",
                type: "warn",
                text: data,
                duration: 5000,
                speed: 1000,
              });
            }
          }
        }
      );
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            startdate: this.searchData.startdate,
            enddate: this.searchData.enddate,
            type: this.searchData.type,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return pageNum === 1
        ? `?startdate=${this.searchData.startdate}&enddate=${this.searchData.enddate}&type=${this.searchData.type}`
        : `?page=${pageNum}&startdate=${this.searchData.startdate}&enddate=${this.searchData.enddate}&type=${this.searchData.type}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_membereventwinninglist();
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.get_membereventwinninglist();
    },
  },
};
</script>
