<template>
  <div>
    <location />

    <div class="p16 page">
      <tab />

      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        >
          <div class="wrap-1200">
            <b-form @submit="onSubmit">
              <div class="date-search">
                <div class="input-date-box">
                  <input
                    type="date"
                    name="startdate"
                    v-model="searchData.startdate"
                  />
                  <input
                    type="date"
                    name="enddate"
                    v-model="searchData.enddate"
                  />
                  <input
                    class="btn btn-primary search"
                    type="submit"
                    value=""
                  />
                </div>
              </div>
            </b-form>
          </div>

          <table class="table board wrap-1200" width="100%" cellspacing="0">
            <thead>
              <tr>
                <th class="col-auto d-lg-table-cell">매장</th>
                <th class="col-auto d-lg-table-cell">코스</th>
                <th class="col-auto d-none d-lg-table-cell">홀</th>
                <th class="col-auto d-none d-lg-table-cell">타수</th>
                <th class="col-auto d-none d-lg-table-cell">날짜</th>
                <th class="col-auto d-none d-lg-table-cell">상태</th>
                <th class="col-auto d-none d-lg-table-cell">스코어카드</th>
                <th class="th-arrow">&nbsp;</th>
              </tr>
            </thead>
            <tbody v-if="data_yn">
              <template v-for="(item, index) in items">
                <tr class="btn1" @click="toggle_tr(index)" :key="index">
                  <td class="col-auto d-lg-table-cell">{{ item.shopname }}</td>
                  <td class="col-auto d-lg-table-cell">
                    {{ item.coursename }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    {{ item.hole + " 홀" }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    {{ item.shot | comma }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell date">
                    {{ item.date | GMTtuUTCTIME }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    {{ change_status(item.status) }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    <router-link
                      :to="{
                        name: 'MyStatusView',
                        params: { id: item.gameno },
                        query: {
                          startdate: searchData.startdate,
                          enddate: searchData.enddate,
                        },
                      }"
                      class="btn btn-outline-primary btn-xs"
                      >스코어카드</router-link
                    >
                  </td>
                  <td class="td-arrow">
                    <img
                      src="/img/arrow_none_collapsed.png"
                      :class="{ 'btn-toggle-arrow': view_hide === index }"
                    />
                  </td>
                </tr>
                <tr
                  class="col-auto d-lg-none d-md-tabel-cell"
                  :class="{ show: view_hide === index }"
                  :key="index + 'r'"
                  v-if="view_hide === index"
                >
                  <td class="align-left bg-white" colspan="7">
                    <!-- a class="btn1 btn_view" style="">
                      <img src="/img/ico_edit.png" />
                    </a -->
                    <div class="layer1">
                      <div class="row g-0">
                        <div class="col-4">홀</div>
                        <div class="col-8">
                          {{ item.hole + "홀" }}
                        </div>
                      </div>
                      <div class="row g-0">
                        <div class="col-4">타수</div>
                        <div class="col-8">
                          {{ item.shot | comma }}
                        </div>
                      </div>
                      <div class="row g-0">
                        <div class="col-4">날짜</div>
                        <div class="col-8 date">
                          {{ item.date | GMTtuUTCTIME }}
                        </div>
                      </div>
                      <div class="row g-0">
                        <div class="col-4">상태</div>
                        <div class="col-8">
                          {{ change_status(item.status) }}
                        </div>
                      </div>
                      <div class="row g-0">
                        <div class="col-4">스코어카드</div>
                        <div class="col-8">
                          <router-link
                            :to="{
                              name: 'MyStatusView',
                              params: { id: item.gameno },
                              query: {
                                startdate: searchData.startdate,
                                enddate: searchData.enddate,
                              },
                            }"
                            class="btn btn-primary btn-xs"
                            >스코어카드</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7" class="text-center">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="page_total"
            v-model="searchData.pageno"
            use-router
            aria-label="Page navigation example"
            class="mt-3"
            v-if="page_total > 1"
            first-class="prev"
            prev-class="prev"
            next-class="next"
            last-class="next"
            hide-ellipsis
            limit="10"
          >
            <template #first-text>
              <i class="material-icons">keyboard_double_arrow_left</i>
            </template>
            <template #prev-text>
              <i class="material-icons">navigate_before</i>
            </template>
            <template #next-text>
              <i class="material-icons">navigate_next</i>
            </template>
            <template #last-text>
              <i class="material-icons">keyboard_double_arrow_right</i>
            </template>
            <template #page="{ page, active }">
              <a v-if="active">{{ page }}</a>
              <a v-else>{{ page }}</a>
            </template>
          </b-pagination-nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import ax from "@/api/membership";
import "@/filter/common";

export default {
  name: "MyStatus",
  components: {
    location,
    tab,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        startdate:
          this.$route.query.startdate ||
          this.$moment(new Date().setMonth(new Date().getMonth() - 1)).format(
            "YYYY-MM-DD"
          ),
        enddate:
          this.$route.query.enddate ||
          this.$moment(new Date()).format("YYYY-MM-DD"),
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      view_hide: null,
    };
  },
  methods: {
    get_memberplaylist() {
      ax.get_memberplaylist(this.searchData, (flag, data, code = "") => {
        if (flag) {
          if (data.playlistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.playlistcnt;
          this.page_total = Math.ceil(data.playlistcnt / this.rows);
          this.items = data.playlist;
        } else {
          this.data_yn = false;
          this.page_total = 0;
          if (code == "300") {
            this.$notify({
              group: "alert",
              type: "warn",
              text: data,
              duration: 5000,
              speed: 1000,
            });
          } else alert(data);
          if (code === "110") {
            this.$store.dispatch("logout");
            this.$router.push("/");
          }
          // alert(data);
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            startdate: this.searchData.startdate,
            enddate: this.searchData.enddate,
          },
        })
        .catch(() => {});
      this.get_memberplaylist();
    },
    linkGen(pageNum) {
      return pageNum === 1
        ? `?startdate=${this.searchData.startdate}&enddate=${this.searchData.enddate}`
        : `?page=${pageNum}&startdate=${this.searchData.startdate}&enddate=${this.searchData.enddate}`;
    },
    change_status(status) {
      if (status === "1") return "대기";
      else if (status === "2") return "진행";
      else if (status === "3") return "이동";
      else if (status === "4") return "중단";
      else if (status === "5") return "중지";
      else if (status === "6") return "종료";
      // else return "대기";
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_memberplaylist();
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.get_memberplaylist();
    },
  },
};
</script>