<template>
  <div>
    <location />

<!--     <div class="p08 p13">
      <div class="wrap">
        <b-form @submit="onSubmit">
          <input
            type="password"
            class="form-control form-control-lg mb-4"
            placeholder="비밀번호 영문, 숫자 조합 8~30자"
            aria-label=""
            v-model="password"
          />
          <input
            class="btn btn-primary btn-lg mb-4"
            type="submit"
            value="확인"
          />
          <p class="d-inline-block icon_alarm">
            보안을 위해 비밀번호를 한 번 더 입력해 주세요.
          </p>
        </b-form>
      </div>
    </div> -->
    <div class="page account-mng mt-0">
      <div class="d-flex flex-fill justify-content-center">
        <b-form @submit="onSubmit" class="text-center">
          <input
            type="password"
            class="form-control form-control-lg mb-2"
            placeholder="비밀번호 영문, 숫자 조합 8~30자"
            aria-label=""
            v-model="password"
          />
          <input
            class="btn btn-primary btn-lg w-100 mb-4"
            type="submit"
            value="확인"
          />
          <p class="d-inline-block icon_alarm">
            보안을 위해 비밀번호를 한 번 더 입력해 주세요.
          </p>
        </b-form>
      </div>
    </div>

  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/membership";

export default {
  name: "ConfirmPassword",
  components: {
    location,
  },
  data() {
    return {
      password: "",
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (this.password === "") {
        alert("비밀번호를 입력해 주세요.");
        return false;
      }

      ax.memberpwd_recheck(this.password, (flag, data, message) => {
        if (flag) {
          alert(message);
          this.$router.push({
            name: "CancelMember",
            params: { password_yn: "y" },
          });
        } else {
          alert(message);
          return false;
        }
      });
    },
  },
};
</script>
