<template>
  <div>
    <location />

    <div class="p14 page">
      <account-tab />
      <div class="tab-content wrap-1200" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-3"
          role="tabpanel"
          aria-labelledby="pills-3-tab"
        >
          <p class="start-m">
            귀하의 계정을 영구적으로 삭제하기 전에 다음을 확인하십시오.
          </p>
          <span class="lh-base">
            X-GOLF는 1년 동안 회원님의 데이터를 유지하고 그 후에 영구적으로
            삭제됩니다. 사용자 데이터가 영구 삭제될 때까지 기존의 닉네임과
            아이디를 사용할 수 없게 됩니다. 귀하의 계정은 바로 서버에서
            비활성화되지만 일부 콘텐츠는 비활성화 후에도 보일 수 있습니다.
          </span>
          <span class="icon_alarm"
            >안녕하세요!
            <em class="text-black text-decoration-none">{{ form.id }}</em
            >님, 회원 탈퇴를 취소하시려면 아래 '<em>취소</em>' 버튼을
            클릭하세요.</span
          >

<!--      <div class="row g-0 flex-nowrap center-btn wrap-1200 justify-content-center">
            <div class="col-lg-3">
              <button
                class="btn btn-outline-secondary btn-xl"
                type="button"
                @click="$router.push({ name: 'main' })"
              >
                취소
              </button>
            </div>
            <div class="col-lg-3">
              <button
                class="btn btn-secondary btn-xl"
                type="button"
                @click="drop_member()"
              >
                회원탈퇴
              </button>
            </div>
          </div> -->
          <div class="btn-bottom-wrapper">
            <b-button size="xl" variant="outline-primary" aria-label="취소" @click="$router.push({ name: 'main' })">취소</b-button>
            <b-button size="xl" variant="primary" aria-label="회원탈퇴" @click="drop_member()">회원탈퇴</b-button>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import AccountTab from "@/components/membership/AccountTab";
import ax from "@/api/membership";

export default {
  name: "CancelMember",
  components: {
    location,
    AccountTab,
  },
  data() {
    return {
      form: {},
    };
  },
  props: ["password_yn"],
  methods: {
    step_chk() {
      if (this.password_yn !== "y") {
        alert("비밀번호 확인이 필요합니다.");
        this.$router.push({ name: "ConfirmPassword" });
      }
    },
    get_memberinfo() {
      ax.get_memberinfo((flag, data) => {
        if (flag) {
          this.form = data.memberinfo;
        } else {
          alert(data);
        }
      });
    },
    drop_member() {
      ax.member_drop((flag, data) => {
        if (flag) {
          alert(data);
          this.$store.dispatch("logout");
          this.$router.push({ name: "main" });
        } else {
          alert(data);
        }
      });
    },
  },
  created() {
    this.step_chk();
    this.get_memberinfo();
  },
};
</script>
